// @ts-nocheck
import React, { useContext, useEffect, useRef, useState } from "react";

import { ReactComponent as RoseBoussole } from "../../svg/rose.svg";
import { ReactComponent as IconChangeDirection } from "../../svg/icon-change-direction.svg";
import { AppContext } from "../../engine/App.context";

export default function Rose() {
  const {
    svgPlan,
    layerHovered,
    layerClicked,
    previousLayerClicked,
    units,
    previousLayerHovered,
    layerOnClick,
    layersClicked,
    addLayerClicked,
    isMobile,
    direction,
    setDirection,
    svg_north,
    svg_south,
    svg_east,
    svg_west,
  } = useContext(AppContext);

  const roseRef = useRef();

  const handleDirectionClick = (d) => {
    setDirection(d);
  };

  const directions = [
    svg_north !== null ? { name: "north", className: "north" } : null,
    svg_east !== null ? { name: "east", className: "east" } : null,
    svg_south !== null ? { name: "south", className: "south" } : null,
    svg_west !== null ? { name: "west", className: "west" } : null,
  ].filter((e) => e !== null);

  useEffect(() => {
    // Store event listeners for cleanup
    const listeners = [];

    directions.forEach((direction) => {
      const elements = roseRef.current.querySelectorAll(
        `.${direction.className}`
      );
      elements.forEach((element) => {
        const listener = () => handleDirectionClick(direction.name);
        element.addEventListener("click", listener);
        listeners.push({ element, listener });
      });
    });

    // Cleanup function to remove event listeners
    return () => {
      listeners.forEach(({ element, listener }) => {
        element.removeEventListener("click", listener);
      });
    };
  }, []);

  const labels = {
    north: "Nord",
    south: "Sud",
    east: "Est",
    west: "Ouest",
  };

  const nextDirection = () => {
    let currentIndex = directions.findIndex((e) => e.name === direction);
    let nextIndex = currentIndex + 1;

    if (nextIndex > directions.length - 1) {
      nextIndex = 0;
    }
    setDirection(directions[nextIndex].name);
  };

  return (
    <div
      onClick={nextDirection}
      key={"rose"}
      style={{
        cursor: "pointer",
        position: "absolute",
        right: 22,
        top: 22,
        paddingTop: "10px",
        paddingBottom: "10px",
        paddingRight: "12px",
        paddingLeft: "12px",
        background: "#ffffff7a",
        textAlign: "center",
        borderRadius: 5,
      }}
    >
      <IconChangeDirection
        width={"100%"}
        ref={(el) => (roseRef.current = el)}
      />
      <span style={{ color: "#838383", fontSize: "10px", fontWeight: 300 }}>
        orientation
      </span>
      <br />
      <span style={{ color: "#1F2937", fontSize: "18px" }}>
        {labels[direction]}
      </span>
    </div>
  );
}
