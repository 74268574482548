import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { IntlProvider } from "react-intl";
import { ConfigProvider } from "antd";

import frTranslations from "./utils/langs/fr.json";
import { colors } from "./utils/constants";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const element = document.getElementById("root");
const newColor = colors.primary;
element.style.setProperty("--primary", newColor);

const data = {
  colorPrimary: newColor,
  borderRadius: 5,
  Button: {
    colorPrimary: newColor,
    algorithm: "lighten",
    padding: "0 16px",
  },
};

root.render(
  <React.StrictMode>
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: data.colorPrimary,
          borderRadius: data.borderRadius,
        },
        components: {
          Button: {
            colorPrimary: data.Button?.colorPrimary,
          },
        },
      }}
    >
      <App />
    </ConfigProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
