// @ts-nocheck
import * as React from "react";

import svgjson, { stringify } from "svgson";

import { http, setAuthorizationHeader } from "../utils/http";
import { apiUrl } from "../utils/constants";
import axios, { AxiosResponse } from "axios";
import { Configuration } from "./Configuration.interface";
import { getMinMaxPrice } from "../utils/helpers";

const defaultContext = {};

export const AppContext = React.createContext(defaultContext);

interface StateType {
  configuration: Configuration;
}

const initialConfiguration = {
  front: {
    filter_status: ["rented", "sold", "reserved", "available"],
    filter_room: true,
    filter_floor: true,
    type_price: "rent",
    filter_renting_slider: true,
    filter_selling_slider: true,
    filter_surface: true,
    unit_labels: ["surface", "balcon", "cave", "charges"],
    unit_labels_top: ["name", "floor", "numberRoom", "status"],
    unit_buttons: ["location", "unit"],
  },
  back: {},
  design: {
    color: "",
    mainColor: "",
  },
};

const initialState: StateType = {
  direction: "north",
  loading: false,
  ready: true,
  units: [],
  projects: null,
  configuration: initialConfiguration,
  orientation: null,
  svgPlan: null,
  svg_north: null,
  svg_south: null,
  svg_east: null,
  svg_west: null,
  layerClicked: null,
  layerHovered: null,
  previousLayerClicked: null,
  previousLayerHovered: null,
  polygonClicked: null,
  layersClicked: new Set(),
  roomsSearch: new Set(),
  floorsSearch: new Set(),
  unitOpen: null,
  isMobile: false,
  surfaceSearch: [20, 300],
  amountSearch: [1200, 5000],
  amountSellingSearch: [1200, 5000],
  filterUniqueUnit: false,
  minMaxPriceSelling: { min: 0, max: 0 },
  minMaxSurface: { min: 0, max: 0 },
  minMaxPrice: { min: 0, max: 0 },
  filterStatus: null,
};

export class AppProvider extends React.Component {
  resetFilters = () => {
    this.setState({
      roomsSearch: initialState.roomsSearch,
      floorsSearch: initialState.floorsSearch,
      surfaceSearch: [
        this.state.minMaxSurface.min,
        this.state.minMaxSurface.max,
      ],
      amountSearch: [this.state.minMaxPrice.min, this.state.minMaxPrice.max],
      amountSellingSearch: [
        this.state.minMaxPriceSelling.min,
        this.state.minMaxPriceSelling.max,
      ],
      filterStatus: initialState.filterStatus,
      filterUniqueUnit: initialState.filterUniqueUnit,
    });
  };
  updateNumberSearch = (key, values) => {
    this.setState({
      [key]: values,
    });
  };
  updateDimensions = () => {
    const { isMobile, svgPlan } = this.state;
    if (window.innerWidth < 850) {
      if (!isMobile) {
        if (svgPlan === null) {
          this.setState({ isMobile: true });
        } else {
          svgjson.parse(svgPlan).then((e) => {
            e.attributes.preserveAspectRatio = "xMidYMin slice";
            const text = svgjson.stringify(e);
            this.setState({ isMobile: true, svgPlan: text });
          });
        }
      }
    } else {
      if (isMobile) {
        if (svgPlan === null) {
          this.setState({ isMobile: false });
        } else
          svgjson.parse(svgPlan).then((e) => {
            e.attributes.preserveAspectRatio = "xMidYMid slice";
            const text = svgjson.stringify(e);
            this.setState({ isMobile: false, svgPlan: text });
          });
        {
        }
      }
    }
  };

  initSvg = async (svg) => {
    if (svg === undefined) {
      return null;
    } else {
      const parsed = await svgjson.parse(svg);
      parsed.attributes.preserveAspectRatio = "xMidYMid slice";

      return svgjson.stringify(parsed);
    }
  };
  async componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);

    await this.init();
  }

  getConfiguration = () => {};
  addLayerClicked = (id, scrollTo = false) => {
    const { layersClicked, previousLayerClicked } = this.state;

    const [first] = layersClicked;

    // Create a new Set from the existing one to avoid direct mutation
    let updatedLayersClicked = new Set(layersClicked);

    if (updatedLayersClicked.has(id)) {
      // If the ID is already in the set, remove it
      updatedLayersClicked.delete(id);
    } else {
      // If the ID is not in the set, add it
      updatedLayersClicked = new Set([id]);
    }

    this.setState({
      layersClicked: updatedLayersClicked,
      scrollTo: scrollTo && updatedLayersClicked.has(id) ? id : null,
      previousLayerClicked:
        first === undefined
          ? null
          : first === id
          ? previousLayerClicked
          : first,
    });

    // OLD logic to add the set
    // const items = new Set(layersClicked);
    //
    // if (items.has(id)) {
    //   items.delete(id);
    //   this.setState({
    //     layersClicked: items,
    //   });
    // } else {
    //   this.setState({
    //     scrollTo: scrollTo ? id : null,
    //     layersClicked: new Set(items).add(id),
    //   });
    // }
  };

  addRoomSearch = (number) => {
    const { roomsSearch } = this.state;
    const items = new Set(roomsSearch);

    if (items.has(number)) {
      items.delete(number);
      this.setState({
        roomsSearch: items,
      });
    } else {
      this.setState({
        roomsSearch: new Set(items).add(number),
      });
    }
  };

  addFloorSearch = (number) => {
    const { floorsSearch } = this.state;
    const items = new Set(floorsSearch);

    if (items.has(number)) {
      items.delete(number);
      this.setState({
        floorsSearch: items,
      });
    } else {
      this.setState({
        floorsSearch: new Set(items).add(number),
      });
    }
  };

  setUnitOpen = (id) => {
    this.setState({ unitOpen: id });
  };

  layerOnClick = (layerId) =>
    this.setState({
      layerClicked: layerId,
      previousLayerClicked: this.state.layerClicked,
    });

  layerOnHover = (layerId) =>
    this.setState({
      layerHovered: layerId,
    });

  layerOnLeaverHover = (layerId) =>
    this.setState({
      layerHovered: null,
      previousLayerHovered: layerId,
    });

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  init = async () => {
    this.updateDimensions();
    // Use [
    //   '{{repeat(1, 2)}}',
    //   {
    //     id: 'lot_{{index()}}',
    //     floor: '{{integer(1, 4)}}',
    //      nbPieces: '{{integer(1, 5)}}',
    //     status: '{{random("available", "rented", "reserved")}}',
    //     price: '{{floating(1000, 4000, 2)}}',
    //     priceCharge: '{{floating(200, 1000, 2)}}',
    //     surfaceHabitable: '{{integer(50, 300)}}',
    //     surfaceTotal: '{{integer(100, 500)}}',
    //     cave: '{{integer(5, 30)}}',
    //     balcon: '{{integer(0, 10)}}'
    //   }
    // ]
    // https://json-generator.com/

    const units = await http.get(`units`);
    const configuration = await http.get(`configurations`);

    const minMaxPrice = getMinMaxPrice(units.data, "price");
    const minMaxSurface = getMinMaxPrice(units.data, "areaTotal");
    const minMaxPriceSelling = getMinMaxPrice(units.data, "priceSelling");

    // const configuration = await http.get(`/configuration`);
    // const svgData = await http
    // .get(`/svg`);model.svg
    const syncSvg = async () => {
      return http
        .get("configurations/svg", {
          headers: {
            "Cache-Control": "no-cache",
            Pragma: "no-cache",
            Expires: "0",
          },
        })
        .then((response: AxiosResponse) => {
          return response.data;
        });
      // return axios({
      //   url:
      //     process.env.NODE_ENV === "development"
      //       ? "/model.svg"
      //       : "https://backoffice.niseko.ch/model.svg",
      //   method: "GET",
      // }).then((response: AxiosResponse) => {
      //   return response.data;
      // });
    };

    const sv = await syncSvg();
    // const svg_uri = await this.initSvg(sv.directions.svg_uri);
    const svg_north = await this.initSvg(sv.directions.svg_north);
    const svg_south = await this.initSvg(sv.directions.svg_south);
    const svg_east = await this.initSvg(sv.directions.svg_east);
    const svg_west = await this.initSvg(sv.directions.svg_west);

    // const convert = svgjson.stringify(svgData.data);

    // Update data as configuration ask it
    let filteredUnits = units.data.filter((e) => {
      return configuration.data.front.filter_status.includes(e.status);
    });

    // filteredUnits = filteredUnits.filter((e) => {
    //   if (configuration.data.front.type_price === "rent") {
    //     return e.info.price !== 0;
    //   }
    //
    //   if (configuration.data.front.type_price === "sell") {
    //     return e.info.priceSelling !== 0;
    //   }
    // });

    this.setState(
      {
        minMaxPriceSelling,
        minMaxSurface,
        minMaxPrice,
        direction: configuration.data?.back?.svg_main,
        surfaceSearch: [minMaxSurface.min, minMaxSurface.max],
        amountSearch: [minMaxPrice.min, minMaxPrice.max],
        amountSellingSearch: [minMaxPriceSelling.min, minMaxPriceSelling.max],
        units: filteredUnits,
        configuration: configuration.data,
        // configuration: configuration.data,
        ready: true,
        // svgPlan: svg_uri,
        svg_north,
        svg_south,
        svg_east,
        svg_west,
      },
      () => {
        this.updateDimensions();
      }
    );
  };

  login = async (email: string, pwd: string) => {
    try {
      this.setState({
        loading: true,
      });

      const response = await axios.post(`auth/login`, {
        email: email.trim(),
        password: pwd,
      });

      this.setState({
        loading: false,
      });
      return true;
    } catch (e) {}
  };

  setCheckedUniqueUnit = () => {
    this.setState({ filterUniqueUnit: !this.state.filterUniqueUnit });
  };

  setFilter = (status) => {
    this.setState({ filterStatus: status });
  };

  setDirection = (d) => {
    const { direction } = this.state;
    if (direction !== d) {
      this.setState({
        direction: d,
        layerClicked: null,
        layerHovered: null,
        previousLayerClicked: null,
        previousLayerHovered: null,
        polygonClicked: null,
        layersClicked: new Set(),
      });
    }
  };

  state = {
    ...initialState,
    layerOnClick: this.layerOnClick,
    layerOnHover: this.layerOnHover,
    layerOnLeaverHover: this.layerOnLeaverHover,
    addLayerClicked: this.addLayerClicked,
    addRoomSearch: this.addRoomSearch,
    addFloorSearch: this.addFloorSearch,
    setUnitOpen: this.setUnitOpen,
    updateNumberSearch: this.updateNumberSearch,
    setCheckedUniqueUnit: this.setCheckedUniqueUnit,
    resetFilters: this.resetFilters,
    setFilter: this.setFilter,
    setDirection: this.setDirection,
  };

  render() {
    // @ts-ignore
    const { children } = this.props;

    return (
      <AppContext.Provider value={this.state}>{children}</AppContext.Provider>
    );
  }
}

export const withApp =
  (Component: JSX.Element) => (props: JSX.ElementAttributesProperty) =>
    (
      <AppContext.Consumer>
        {(store) => <Component app={store} {...props} />}
      </AppContext.Consumer>
    );
