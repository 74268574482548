/**
 * Returns an object containing the minimum and maximum values of a specified property in an array of objects.
 * @param {Object[]} arr - The array of objects to search.
 * @param {string} [key="price"] - The name of the property to search for. Defaults to "price".
 * @returns {Object} An object with the min and max values of the specified property.
 */
export function getMinMaxPrice(arr, key = "price") {
  // Check if arr is not an array or is empty
  if (!Array.isArray(arr) || arr.length === 0) {
    // Return default values if no valid values found
    return { min: 0, max: 0 };
  }

  // Initialize variables to hold min and max values
  let minPrice = Number.MAX_SAFE_INTEGER;
  let maxPrice = Number.MIN_SAFE_INTEGER;

  // Loop through each object in the array
  arr.forEach((obj) => {
    // Check if the specified property is a finite number and is not equal to 0
    if (
      typeof obj.info[key] === "number" &&
      isFinite(obj.info[key])
    ) {
      // && obj.info[key] !== 0
      //
      // Update minPrice if the current value is less than the current minimum
      if (obj.info[key] < minPrice) {
        minPrice = obj.info[key];
      }

      // Update maxPrice if the current value is greater than the current maximum
      if (obj.info[key] > maxPrice) {
        maxPrice = obj.info[key];
      }
    }
  });

  // Check if no valid values were found
  if (
    minPrice === Number.MAX_SAFE_INTEGER ||
    maxPrice === Number.MIN_SAFE_INTEGER
  ) {
    // Return default values if no valid values found
    return { min: 0, max: 0 };
  }

  // Return the min and max values as an object
  return { min: minPrice, max: maxPrice };
}
