import { Button, message, Upload } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import React, { useState } from "react";

export const Uploader = ({ refForm, item, index }) => {
  const filesU = refForm.current.getFieldValue(item.name);

  return (
    <Upload
      maxCount={3}
      fileList={filesU}
      style={{ padding: 0, margin: 0 }}
      listType="text"
      accept=".pdf,.png,.jpg,.jpeg" // Accept only PDF, PNG, and JPG files
      beforeUpload={(file) => {
        const isLt5M = file.size / 1024 / 1024 < 5;
        if (!isLt5M) {
          message.error("Le fichier doit être inférieur à 5MB !");
        }
        return false;
      }}
      onChange={({ fileList: newFileList }) => {
        console.log(newFileList);
        // Filter out files larger than 5MB
        const filteredList = newFileList.filter(
          (file) => file.size / 1024 / 1024 < 5
        );

        // setFileList(filteredList);

        // Update form state here
        refForm.current.setFieldsValue({
          [item.name]: filteredList,
        });
      }}
    >
      <Button
        icon={<UploadOutlined />}
        type={"dashed"}
        style={{ background: "white" }}
      >
        Cliquez pour uploader
      </Button>
    </Upload>
  );
};
