import { labelPreneurDuBail, labelSecondPlaceHolder } from "./form.constants";
import dayjs from "../../utils/dayjs";

export const inputsSecond = [
  {
    parentLabel: "Informations",
    children: [
      {
        label: "Si moins d'une année, ancienne adresse",
        placeholder: labelPreneurDuBail,
        name: "old_adress",
        type: "input",
        required: true,
        col: 12,
      },
      {
        label: "",
        placeholder: labelSecondPlaceHolder,
        name: "old_adress_2",
        type: "input",
        col: 12,
      },
      {
        label: "No de Tél. Privé",
        placeholder: labelPreneurDuBail,
        name: "private_phone",
        type: "input",
        col: 12,
      },
      {
        label: "",
        placeholder: labelSecondPlaceHolder,
        name: "private_phone_2",
        type: "input",
        col: 12,
      },
      {
        label: "No de Tél. Prof",
        placeholder: labelPreneurDuBail,
        name: "prof_phone",
        type: "input",
        col: 12,
      },
      {
        label: "",
        placeholder: labelSecondPlaceHolder,
        name: "prof_phone_2",
        type: "input",
        col: 12,
      },
      {
        label: "No de Tél. Mobile",
        placeholder: labelPreneurDuBail,
        name: "mobile_phone",
        type: "input",
        required: true,
        col: 12,
      },
      {
        label: "",
        placeholder: labelSecondPlaceHolder,
        name: "mobile_phone_2",
        type: "input",
        col: 12,
      },
      {
        label: "Adresse email",
        placeholder: labelPreneurDuBail,
        name: "email",
        type: "input",
        required: true,
        col: 12,
      },
      {
        label: "",
        placeholder: labelSecondPlaceHolder,
        name: "email_2",
        type: "input",
        col: 12,
      },
      {
        label: "Profession",
        placeholder: labelPreneurDuBail,
        name: "job",
        type: "input",
        required: true,
        col: 12,
      },
      {
        label: "",
        placeholder: labelSecondPlaceHolder,
        name: "job_2",
        type: "input",
        col: 12,
      },
      {
        label: "Nom, adresse et Tél. de l'employeur",
        placeholder: labelPreneurDuBail,
        name: "job_info",
        type: "textarea",
        rows: 2,

        col: 12,
      },
      {
        label: "",
        placeholder: labelSecondPlaceHolder,
        name: "job_info_2",
        type: "textarea",
        rows: 2,
        col: 12,
      },
      {
        label: "Engagé(e) depuis le :",
        placeholder: labelPreneurDuBail,
        name: "job_since",
        type: "date",
        default: dayjs(),
        col: 12,
      },
      {
        label: "",
        placeholder: labelSecondPlaceHolder,
        name: "job_since_2",
        type: "date",
        col: 12,
      },
      {
        label: "Salaire mensuel net",
        placeholder: labelPreneurDuBail,
        name: "salairy",
        addonBefore: "CHF",
        type: "input-currency",
        required: true,
        col: 12,
      },
      {
        label: "",
        placeholder: labelSecondPlaceHolder,
        name: "salairy_2",
        addonBefore: "CHF",
        type: "input-currency",
        col: 12,
      },
      {
        label: "Nom, adresse gérance / propriétaire actuel",
        placeholder: labelPreneurDuBail,
        name: "current_agency",
        addonBefore: "CHF",
        type: "textarea",
        required: true,
        rows: 2,
        col: 12,
      },
      {
        label: "",
        placeholder: labelSecondPlaceHolder,
        name: "current_agency_2",
        addonBefore: "CHF",
        type: "textarea",
        rows: 2,
        col: 12,
      },
      {
        label: "Télépone Gérance / propriétaire actuel",
        placeholder: labelPreneurDuBail,
        name: "current_agency_contact",
        type: "input",
        required: true,
        col: 12,
      },
      {
        label: "",
        placeholder: labelSecondPlaceHolder,
        name: "current_agency_contact_2",
        type: "input",
        col: 12,
      },
      {
        label: "Motifs du changement de domicile",
        placeholder: labelPreneurDuBail,
        name: "why_changing_house",
        type: "input",
        required: true,
        col: 12,
      },
      {
        label: "",
        placeholder: labelSecondPlaceHolder,
        name: "why_changing_house_2",
        type: "input",
        col: 12,
      },
    ],
  },
];
