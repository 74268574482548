// @ts-nocheck

import { UnitType } from "../engine/Interface";
import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../engine/App.context";
import Size from "../icons/size.png";
import Layer from "../icons/layer.png";
import Charge from "../icons/charge.png";
import "../styles/Custom.css";
import {
  colorByStatus,
  colors,
  formatCurrency,
  getOrientationString,
  statusUnit,
} from "../utils/constants";

import Deal from "../icons/deal.png";
import Available from "../icons/available.png";
import Lock from "../icons/padlock.png";

import { FormattedMessage, useIntl } from "react-intl";
import { Configuration } from "../engine/Configuration.interface";
import { ModalApplicationForm } from "./ModalApplicationForm/ModalApplicationForm";
/* tslint:disable */
type Props = {
  unit: UnitType;
  key: string;
};
export default function Unit(props: Props) {
  // @ts-ignore
  const {
    layerOnHover,
    layerOnLeaverHover,
    addLayerClicked,
    scrollTo,
    unitOpen,
    setUnitOpen,
  } = useContext(AppContext);
  const { isMobile } = useContext(AppContext);

  const { configuration } = useContext<AppContext>(AppContext) as {
    configuration: Configuration;
  };

  const { front } = configuration;

  const [open, setOpen] = useState(false);

  const { unit } = props;
  const intl = useIntl();

  useEffect(() => {
    if (scrollTo !== null && scrollTo === unit.id) {
      const element = document.getElementById(unit.id + "-box");

      if (element) {
        // 👇 Will scroll smoothly to the top of the next section
        element.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "center",
        });
        setOpen(true);
        setUnitOpen(id);
      }
    }
  }, [scrollTo]);

  const {
    id,
    extra: { cellar, attic, garage, balcony, garden, styleFr, styleDe },
    type,
    status,
    info: {
      price,
      orientationDe,
      orientationFr,
      numberRoom,
      areaLiving,
      areaTotal,
      floor,
      floorDe,
      name,
      urlDocument,
      priceCharge,
      priceSelling,
      orientation,
    },
  } = unit;

  const onMouseEnter = () => {
    layerOnHover(id);
  };

  const onClickUnit = () => {
    addLayerClicked(id);

    if (unitOpen === id) {
      setUnitOpen(null);
    } else {
      setUnitOpen(id);
    }

    // if (unitOpen === null) {
    //   setUnitOpen(id);
    // } else {
    //   setUnitOpen(null);
    // }
  };
  const onMouseLeave = () => {
    layerOnLeaverHover(id);
  };

  const urlImageByStatus = () => {
    switch (status) {
      case "available":
        return Available;
      case "reserved":
        return Lock;
      case "rented":
      case "sold":
        return Deal;
    }
  };

  // @ts-ignore
  return (
    <div
      id={unit.id + "-box"}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      className="flex mx-2 mb-2 unit-box flex-col cursor-pointer  "
      style={
        unitOpen === id
          ? { borderColor: colors.primary, background: "#f7f7ff" }
          : { borderColor: colors.lightgray }
      }
    >
      <div
        onClick={onClickUnit}
        className={`w-full items-start ${
          isMobile ? "px-1 py-1" : "px-3 py-3"
        }   flex`}
      >
        {front.unit_labels_top.includes("name") && (
          <div className={"flex-1 flex-col flex text-center m-2 "}>
            <span className={"label-card"}>
              <FormattedMessage id={`unit`} />
            </span>
            <h3
              className={` 
             ${isMobile ? "text-sm" : "text-lg"}
            font-bold  -mt-1`}
            >
              {name}
            </h3>
          </div>
        )}

        {front.unit_labels_top.includes("floor") && (
          <div className={"flex-1 flex-col flex  text-center m-2"}>
            <span className={"label-card"}>
              <FormattedMessage id={`etages`} />
            </span>
            <h4
              className={` ${
                isMobile ? "text-sm" : "text-lg"
              } -mt-1  font-bold truncate`}
            >
              {intl.locale === "fr" && floor}
              {intl.locale === "de" && floorDe}
            </h4>
          </div>
        )}

        {front.unit_labels_top.includes("numberRoom") && (
          <div className={"flex-1 flex-col flex text-center m-2 "}>
            <span className={"label-card"}>
              <FormattedMessage id={`pieces`} />
            </span>
            <h4
              className={` ${
                isMobile ? "text-sm" : "text-lg"
              } -mt-1  font-bold`}
            >
              {numberRoom}
            </h4>
          </div>
        )}

        {front.unit_labels_top.includes("rent") && (
          <div className={"flex-1 flex-col flex text-center m-2 "}>
            <span className={"label-card "}>
              <FormattedMessage id={`prix`} />
            </span>
            <h4
              className={` ${
                isMobile ? "text-sm" : "text-lg"
              } -mt-1  font-bold`}
            >
              {formatCurrency(price)}
              <sup>chf</sup>
            </h4>
          </div>
        )}

        {front.unit_labels_top.includes("surface") && (
          <div className={"flex-1 flex-col flex text-center m-2 "}>
            <span className={"label-card"}>
              <FormattedMessage id={`surface`} />
            </span>
            <h4
              className={` ${
                isMobile ? "text-sm" : "text-lg"
              } text-gray-900 -mt-1 font-light`}
            >
              {areaTotal}
              <sup>m2</sup>
            </h4>
          </div>
        )}

        {status === statusUnit.available &&
        front.unit_labels_top.includes("status") ? (
          <div className={"w-[100px]  flex-col flex  text-center m-2 "}>
            <span
              className={"uppercase font-thin  text-xs"}
              style={{ color: colors.primary }}
            >
              {front.type_price === "rent" && <FormattedMessage id={`brut`} />}

              {front.type_price === "sell" && (
                <FormattedMessage id={"priceselling"} />
              )}
            </span>
            <h4
              className={` ${
                isMobile ? "text-sm" : "text-lg"
              } -mt-1  font-bold`}
              style={{ color: colors.primary }}
            >
              {front.type_price === "rent" && formatCurrency(price)}

              {front.type_price === "sell" &&
                formatCurrency(priceSelling, true)}
            </h4>
          </div>
        ) : null}

        {status === statusUnit.rented &&
        front.unit_labels_top.includes("status") ? (
          <div
            className={
              "w-[100px] flex-col flex h-11 justify-center  text-center m-2   "
            }
          >
            <h2
              className={"text-lg -mt-1 uppercase font-normal "}
              style={{ color: colorByStatus(status) }}
            >
              <FormattedMessage id={`loue`} />
            </h2>
          </div>
        ) : null}

        {status === statusUnit.sold &&
        front.unit_labels_top.includes("status") ? (
          <div
            className={
              "w-[100px] flex-col flex h-11 justify-center  text-center  m-2 "
            }
          >
            <h2
              className={"text-lg -mt-1 uppercase font-normal "}
              style={{ color: colorByStatus(status) }}
            >
              <FormattedMessage id={`vendu`} />
            </h2>
          </div>
        ) : null}

        {status === statusUnit.rented ? (
          <div
            className={
              "w-[100px]  flex-col flex h-11 justify-center  text-center m-2 "
            }
          >
            <h2
              className={
                "text-lg text-gray-900 -mt-1 uppercase inter Inter font-normal"
              }
              style={{ color: colorByStatus(status) }}
            >
              <FormattedMessage id={`loue`} />
            </h2>
          </div>
        ) : null}



        {status === statusUnit.reserved ? (
            <div
                className={
                  "w-[100px]  flex-col flex h-11 justify-center  text-center m-2 "
                }
            >
              <h2
                  className={
                    "text-lg text-gray-900 -mt-1 uppercase inter Inter font-normal"
                  }
                  style={{ color: colorByStatus(status) }}
              >
                <FormattedMessage id={`reserved`} />
              </h2>
            </div>
        ) : null}

        {status === statusUnit.sold ? (
            <div
                className={
                  "w-[100px]  flex-col flex h-11 justify-center  text-center m-2 "
                }
            >
              <h2
                  className={
                    "text-lg text-gray-900 -mt-1 uppercase inter Inter font-normal"
                  }
                  style={{ color: colorByStatus(status) }}
              >
                <FormattedMessage id={`vendu`} />
              </h2>
            </div>
        ) : null}

        <div
          style={{ width: "35px", marginLeft: "5px" }}
          className={`items-center justify-center flex content-center ${
            isMobile ? "mt-4 h-5" : "h-10"
          }  m-2`}
        >
          <svg
            width="100%"
            height="100%"
            viewBox="0 0 15 15"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.25 0.25C5.01387 0.25 3.8055 0.616556 2.77769 1.30331C1.74988 1.99007 0.948803 2.96619 0.475756 4.10823C0.00270803 5.25027 -0.121063 6.50693 0.120095 7.71931C0.361252 8.9317 0.956507 10.0453 1.83059 10.9194C2.70466 11.7935 3.81831 12.3888 5.03069 12.6299C6.24307 12.8711 7.49974 12.7473 8.64178 12.2742C9.78381 11.8012 10.7599 11.0001 11.4467 9.97231C12.1334 8.94451 12.5 7.73613 12.5 6.5C12.5 5.67924 12.3383 4.86651 12.0243 4.10823C11.7102 3.34994 11.2498 2.66095 10.6694 2.08058C10.0891 1.50022 9.40006 1.03984 8.64178 0.725753C7.88349 0.411661 7.07076 0.25 6.25 0.25ZM6.875 9C6.875 9.16576 6.80915 9.32473 6.69194 9.44194C6.57473 9.55915 6.41576 9.625 6.25 9.625C6.08424 9.625 5.92527 9.55915 5.80806 9.44194C5.69085 9.32473 5.625 9.16576 5.625 9V5.875C5.625 5.70924 5.69085 5.55027 5.80806 5.43306C5.92527 5.31585 6.08424 5.25 6.25 5.25C6.41576 5.25 6.57473 5.31585 6.69194 5.43306C6.80915 5.55027 6.875 5.70924 6.875 5.875V9ZM6.25 4.625C6.12639 4.625 6.00555 4.58834 5.90277 4.51967C5.79999 4.45099 5.71988 4.35338 5.67258 4.23918C5.62527 4.12497 5.6129 3.99931 5.63701 3.87807C5.66113 3.75683 5.72065 3.64547 5.80806 3.55806C5.89547 3.47065 6.00683 3.41112 6.12807 3.38701C6.24931 3.36289 6.37498 3.37527 6.48918 3.42258C6.60338 3.46988 6.701 3.54999 6.76967 3.65277C6.83835 3.75555 6.875 3.87639 6.875 4C6.875 4.16576 6.80915 4.32473 6.69194 4.44194C6.57473 4.55915 6.41576 4.625 6.25 4.625Z"
              fill={colorByStatus(status)}
            />
          </svg>
        </div>

        {/*<div*/}
        {/*  style={{ width: "35px", marginLeft: "5px" }}*/}
        {/*  className={`items-center justify-center flex content-center ${*/}
        {/*    isMobile ? "mt-4 h-5" : "h-10"*/}
        {/*  }  m-2`}*/}
        {/*>*/}
        {/*  <img*/}
        {/*    alt={"icon-status-lot"}*/}
        {/*    className={"icon-status-lot"}*/}
        {/*    src={urlImageByStatus()}*/}
        {/*  />*/}
        {/*</div>*/}

        {/*<div className="mt-4 flex items-center justify-between">*/}
        {/*  <div className="flex mr-2 text-gray-700 text-sm mr-2 ">*/}
        {/*    <img src={Size} className="w-5 h-5 mr-1" />*/}
        {/*    <span>{surfaceTotal}m2</span>*/}
        {/*  </div>*/}
        {/*  <div className="flex mr-2 text-gray-700 text-sm mr-2">*/}
        {/*    <img src={Layer} className="w-5 h-5 mr-1" />*/}
        {/*    <span>{etage} étage</span>*/}
        {/*  </div>*/}
        {/*  <div className="flex mr-2 text-gray-700 text-sm mr-2">*/}
        {/*    <img src={Charge} className="w-5 h-5 mr-1" />*/}

        {/*    <span>{prixCharges}.- charge</span>*/}
        {/*  </div>*/}
        {/*</div>*/}
      </div>
      <div
        onClick={onClickUnit}
        style={{
          display: unitOpen === id ? "flex" : "none",
          borderTopWidth: "2px",
          borderTopColor: "white",
        }}
        className={" flex mx-1 my-1 flex-row border-t-1 py-1"}
      >
        {/*{front.unit_labels.includes("surface") && (*/}
        {/*  <div className={"flex-1 flex-col flex  text-center "}>*/}
        {/*    <span className={"label-card"}>*/}
        {/*      <FormattedMessage id={`surface`} />*/}
        {/*    </span>*/}
        {/*    <h4 className={"text-lg text-gray-900 -mt-1 font-light"}>*/}
        {/*      {areaTotal}*/}
        {/*      <sup>m2</sup>*/}
        {/*    </h4>*/}
        {/*  </div>*/}
        {/*)}*/}

        {/*<div className={" flex-1 flex-col flex  text-center "}>*/}
        {/*  <span className={"label-card"}>*/}
        {/*    <FormattedMessage id={`style`} />*/}
        {/*  </span>*/}
        {/*  <h4 className={"text-lg text-gray-900 -mt-1 font-light"}>*/}
        {/*    {intl.locale === "fr" && styleFr}*/}
        {/*    {intl.locale === "de" && styleDe}*/}
        {/*  </h4>*/}
        {/*</div>*/}

        <div className={"flex-1 flex-col flex text-center m-2 "}>
          <span className={"label-card"}>
            <FormattedMessage id={`Orientation`} />
          </span>
          <h3
            className={` 
             ${isMobile ? "text-sm" : "text-lg"}
            font-bold  -mt-1`}
          >
            {getOrientationString(orientation)}
          </h3>
        </div>

        {front.unit_labels.includes("living") && (
          <div className={" flex-1 flex-col flex  text-center "}>
            <span className={"label-card"}>
              <FormattedMessage id={`Surf. Habitable`} />
            </span>
            <h4 className={"text-lg text-gray-900 -mt-1 font-light"}>
              {areaLiving}
              <sup>m2</sup>
            </h4>
          </div>
        )}

        {front.unit_labels.includes("balcon") && (
          <div className={" flex-1 flex-col flex  text-center "}>
            <span className={"label-card"}>
              <FormattedMessage id={`balcon`} />
            </span>
            <h4 className={"text-lg text-gray-900 -mt-1 font-light"}>
              {balcony}
              <sup>m2</sup>
            </h4>
          </div>
        )}

        {front.unit_labels.includes("cave") && (
          <div className={" flex-1 flex-col flex  text-center"}>
            <span className={"label-card"}>
              <FormattedMessage id={`cave`} />
            </span>
            <h4 className={"text-lg text-gray-900 -mt-1 font-light"}>
              {cellar}
              <sup>m2</sup>
            </h4>
          </div>
        )}

        {front.unit_labels.includes("charges") && (
          <div className={"flex-1 flex-col flex text-center "}>
            <span className={"label-card"}>
              <FormattedMessage id={`charges`} />
            </span>
            <h4 className={"text-lg text-gray-900 -mt-1 font-light"}>
              {formatCurrency(priceCharge)}
              <sup>chf</sup>
            </h4>
          </div>
        )}
      </div>
      <div
        style={{
          display: unitOpen === id ? "flex" : "none",
        }}
        className={"w-full flex-row flex mb-3"}
      >
        {front.unit_buttons.includes("unit") && (
          <div className={" flex-1 flex justify-start pl-4 "}>
            <a
              style={{
                backgroundColor: "white",
                color: colors.primary,
                borderWidth: 0,
              }}
              href={unit?.info?.urlSheetPdf || "#"}
              target={"_blank"}
              className="btn text-center btn-sm font-thin p-6 align-baseline flex-nowrap normal-case text-base	"
              onClick={() => {}}
            >
              <FormattedMessage id={`fiche`} />
              <svg
                style={{ marginLeft: 6 }}
                width="5"
                height="9"
                viewBox="0 0 5 9"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M0.398832 0.516654C0.504694 0.410982 0.676176 0.411135 0.781848 0.516996L4.56674 4.30866C4.67228 4.41439 4.67228 4.58561 4.56674 4.69134L0.781848 8.483C0.676176 8.58887 0.504694 8.58902 0.398832 8.48335C0.292971 8.37767 0.292818 8.20619 0.39849 8.10033L3.99239 4.5L0.39849 0.89967C0.292818 0.793809 0.292971 0.622327 0.398832 0.516654Z"
                  fill="#4F46E5"
                />
              </svg>
            </a>
          </div>
        )}

        {front.unit_buttons.includes("location") && (
          <div className={" flex-1 flex justify-center pr-4  text-center"}>
            <ModalApplicationForm unit={unit} />
          </div>
        )}
      </div>

      <div
        style={{
          display: unitOpen === id ? "flex" : "none",
        }}
        className={"w-full flex-row flex mb-3"}
      >
        {unit.info?.urlImage !== "" && unit.info?.urlImage !== null ? (
          <div className={"flex-1"}>
            <img src={unit.info?.urlImage} className={"img-unit-cover"} />
          </div>
        ) : null}
      </div>
    </div>
  );
}
