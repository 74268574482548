import { labelPreneurDuBail, labelSecondPlaceHolder } from "./form.constants";

export const inputsFirst = [
  {
    parentLabel: "",
    children: [
      {
        label: "Nom",
        placeholder: labelPreneurDuBail,
        name: "lastname",
        type: "input",
        required: true,
        col: 12,
      },
      {
        label: "",
        placeholder: labelSecondPlaceHolder,
        name: "lastname_2",
        type: "input",
        required: false,
        col: 12,
      },
      {
        label: "Prénom",
        placeholder: labelPreneurDuBail,
        name: "firstname",
        type: "input",
        required: true,
        col: 12,
      },
      {
        label: "",
        placeholder: labelSecondPlaceHolder,
        name: "firstname_2",
        type: "input",
        required: false,
        col: 12,
      },
      {
        label: "Date de naissance",
        placeholder: labelPreneurDuBail,
        name: "birthdate",
        showTime: false,
        type: "date",
        required: true,
        col: 12,
      },
      {
        label: "",
        placeholder: labelSecondPlaceHolder,
        name: "birthdate_2",
        showTime: false,
        type: "date",
        required: false,
        col: 12,
      },
      {
        label: "Nationalité",
        placeholder: labelPreneurDuBail,
        name: "nationality",
        type: "input",
        required: false,
        col: 12,
      },
      {
        label: "",
        placeholder: labelSecondPlaceHolder,
        name: "nationality_2",
        type: "input",
        required: false,
        col: 12,
      },
      {
        label: "Si étranger, type du permis de séjour (joindre une copie)",
        placeholder: labelPreneurDuBail,
        name: "permit",
        type: "input",
        required: true,
        col: 12,
      },
      {
        label: "",
        placeholder: labelSecondPlaceHolder,
        name: "permit_2",
        type: "input",
        required: false,
        col: 12,
      },
      {
        label: "Etat civil",
        placeholder: labelPreneurDuBail,
        name: "civil",
        type: "input",
        required: true,
        col: 12,
      },
      {
        label: "",
        placeholder: labelSecondPlaceHolder,
        name: "civil_2",
        type: "input",
        required: false,
        col: 12,
      },
      {
        label: "Si marié(e)/séparé(e), depuis quand",
        placeholder: labelPreneurDuBail,
        name: "divorced_since",
        type: "date",
        required: false,
        col: 12,
      },
      {
        label: "",
        placeholder: labelSecondPlaceHolder,
        name: "divorced_since_2",
        type: "date",
        required: false,
        col: 12,
      },
      {
        label: "Nombre d'occupants",
        placeholder: labelPreneurDuBail,
        name: "people_living",
        type: "number",
        required: false,
        col: 12,
      },
      {
        label: "",
        placeholder: labelSecondPlaceHolder,
        name: "people_living_2",
        type: "number",
        required: false,
        col: 12,
      },
      {
        label: "Nombre d'enfants ( moins de 14 ans )",
        placeholder: labelPreneurDuBail,
        name: "children",
        type: "number",
        required: false,
        col: 12,
      },
      {
        label: "",
        placeholder: labelSecondPlaceHolder,
        name: "children_2",
        type: "number",
        required: false,
        col: 12,
      },
      {
        label: "Adresse actuelle complète",
        placeholder: labelPreneurDuBail,
        name: "full_address",
        type: "input",
        required: true,
        col: 12,
      },
      {
        label: "",
        placeholder: labelSecondPlaceHolder,
        name: "full_address_2",
        type: "input",
        required: false,
        col: 12,
      },
      {
        label: "A cette adresse depuis le",
        placeholder: labelPreneurDuBail,
        name: "address_since",
        type: "date",
        required: false,
        col: 12,
      },
      {
        label: "",
        placeholder: labelSecondPlaceHolder,
        name: "address_since_2",
        type: "date",
        required: false,
        col: 12,
      },
    ],
  },
];
