import ColFormItemWrapper from "./ColWFormItemWrapper";
import {
  Button,
  Checkbox,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Radio,
  Select,
  Slider,
  Space,
  Switch,
  Upload,
  message,
} from "antd";
import { currencyFormatter } from "./form.utils";
import SelectAddItem from "./SelectAddItem";
// import SelectFieldAxios from "../../container/SelectFieldAxios";
import AddressForm from "./AddressForm";
import React, { useState } from "react";
// import AutoCompleteCustom from "../AutoCompleteCustom";
import dayjs, { generalFormatAntd } from "../../utils/dayjs";
import { HiOutlineMail } from "react-icons/hi";
import { UploadOutlined } from "@ant-design/icons";
import { Uploader } from "./Uploader";

const Option = Select.Option;

const { RangePicker } = DatePicker;

const { TextArea } = Input;

export const renderlabel = (option) => {
  if (option.label === undefined) {
    return option?.value;
  }

  if (typeof option.label === "string" || option.label instanceof String) {
    return option.label;
  }

  if (option.label instanceof Function) {
    return option.label();
  }
};

export const renderInputType = (
  item,
  index,
  form,
  refForm,
  disabled = false,
  onValuesChange
) => {
  switch (item.type) {
    case "input-currency":
      return (
        <ColFormItemWrapper item={item} index={index} refForm={refForm}>
          <InputNumber
            controls={false}
            formatter={currencyFormatter}
            onChange={item.onChange !== undefined ? item.onChange : () => {}}
            onBlur={(e) => {
              return currencyFormatter(e.target.value);
            }}
            placeholder={item.label}
            style={{ width: "100%" }}
            disabled={item.disabled || disabled}
            {...item}
          />
        </ColFormItemWrapper>
      );
    case "input":
      return (
        <ColFormItemWrapper item={item} index={index} refForm={refForm}>
          <Input
            addonBefore={item.addonBefore}
            onChange={item.onChange !== undefined ? item.onChange : () => {}}
            defaultValue={""}
            disabled={item.disabled || disabled}
            placeholder={
              item.placeholder !== undefined ? item.placeholder : item.label
            }
          />
        </ColFormItemWrapper>
      );
    case "password":
      return (
        <ColFormItemWrapper item={item} index={index} refForm={refForm}>
          <Input.Password
            onChange={item.onChange !== undefined ? item.onChange : () => {}}
            defaultValue={""}
            disabled={item.disabled || disabled}
            placeholder={
              item.placeholder !== undefined ? item.placeholder : item.label
            }
          />
        </ColFormItemWrapper>
      );
    case "slider":
      return (
        <ColFormItemWrapper item={item} index={index} refForm={refForm}>
          <Slider
            max={item.max}
            tooltipVisible={item.tooltipVisible}
            min={item.min}
            range={item.range}
            disabled={item.disabled || disabled}
            placeholder={
              item.placeholder !== undefined ? item.placeholder : item.label
            }
          />
        </ColFormItemWrapper>
      );
    case "textarea":
      return (
        <ColFormItemWrapper item={item} index={index} refForm={refForm}>
          <TextArea
            placeholder={item.label}
            rows={item.rows ? item.rows : 4}
            disabled={item.disabled || disabled}
          />
        </ColFormItemWrapper>
      );
    case "breakline":
      return <div style={{ width: "100%" }} />;
    case "select-add":
      return <SelectAddItem index={index} item={item} />;
    case "switch":
      return (
        <ColFormItemWrapper item={item} index={index} refForm={refForm}>
          <Switch
            checkedChildren={"oui"}
            unCheckedChildren={"non"}
            defaultChecked={item.value}
            disabled={item.disabled || disabled}
          />
        </ColFormItemWrapper>
      );
    case "range-picker":
      return (
        <ColFormItemWrapper item={item} index={index} refForm={refForm}>
          <RangePicker
            style={{ width: "100%" }}
            className={"custom-range-picker"}
            format={"DD/MM/YYYY"}
            disabled={item.disabled || disabled}
          />
        </ColFormItemWrapper>
      );
    case "select":
      return (
        <ColFormItemWrapper item={item} index={index} refForm={refForm}>
          {(propsFromParent = {}) => {
            const { filterChildrenList } = propsFromParent;
            return (
              <Select
                // onChange={(a, b) => {
                //   console.log(a, b, item, index, refForm, form);
                //
                //   if (form !== undefined) {
                //     const data = form.getFieldsValue(true);
                //
                //     form.setFieldsValue({ [item.name]: a });
                //
                //     if (isFunction(onValuesChange)) {
                //       onValuesChange({ [item.name]: a }, data);
                //     }
                //   }
                // }}
                defaultValue={item.defaultValue ? item.defaultValue : undefined}
                onClear={item.onClear}
                allowClear={item.allowClear ? true : false}
                showSearch={item.showSearch ? true : false}
                disabled={item.disabled || disabled}
                mode={item.multiple ? "multiple" : "tag"}
                optionFilterProp={"label"}
                // onSelect={(LabeledValue, option) => {}}
                className={item.select === "colorfull" ? "colorfull" : ""}
                placeholder={
                  item.placeholder !== undefined ? item.placeholder : item.label
                }
                options={
                  item?.selectType === "group"
                    ? item.selectContent.filter(filterChildrenList)
                    : undefined
                }
              >
                {item.selectType !== "group"
                  ? item.selectContent.map((option, i) => (
                      <Option
                        label={option?.label}
                        disabled={option?.disabled}
                        key={option?.key || option?.value}
                        value={option?.value || option?.key}
                        color={option?.color}
                      >
                        {renderlabel(option)}
                      </Option>
                    ))
                  : null}
              </Select>
            );
          }}
        </ColFormItemWrapper>
      );
    case "number":
      return (
        <ColFormItemWrapper item={item} index={index} refForm={refForm}>
          <InputNumber
            controls={false}
            placeholder={item.label}
            style={{ width: "100%" }}
            disabled={item.disabled || disabled}
            {...item}
          />
        </ColFormItemWrapper>
      );
    case "date":
      const { picker = "date" } = item;

      let initalValue = "";

      if (typeof item.value === "string" || item.value instanceof String) {
        initalValue = dayjs(item.value);
      } else {
        initalValue = item.value;
      }

      return (
        <ColFormItemWrapper item={item} index={index} refForm={refForm}>
          <DatePicker
            //locale={frFR}
            placeholder={item.placeholder || item.label}
            suffixIcon={item.suffixIcon}
            addonAfter={item.addonAfter}
            showTime={item.showTime ? { format: "HH:mm" } : false}
            onChange={(d, v) => {}}
            format={generalFormatAntd}
            allowClear={item.allowClear !== undefined ? item.allowClear : false}
            value={dayjs()}
            // value={
            //   typeof item.value === 'string' || item.value instanceof String
            //     ? dayjs(item.value)
            //     : item.value
            // }
            //picker={picker}
            style={{ width: "100%" }}
            disabled={item.disabled || disabled}
          />
        </ColFormItemWrapper>
      );
    case "component":
      return (
        <ColFormItemWrapper item={item} index={index} refForm={refForm}>
          {item.component()}
        </ColFormItemWrapper>
      );
    case "dynamic-select":
      return "Not installed";
    // <SelectFieldAxios
    //   refForm={refForm}
    //   updateEndPoint={item.updateEndPoint}
    //   ready={item.ready}
    //   itemsCallBack={(e) => {
    //     if (item.itemsCallBack !== undefined) {
    //       item.itemsCallBack(e, form);
    //     }
    //   }}
    //   endPoint={item.endPoint}
    //   itemCallback={item.itemCallback}
    //   filterItem={item.filterItem}
    //   item={item}
    // />

    case "children":
      return item.render();
    case "submit":
      return (
        !disabled && (
          <ColFormItemWrapper item={item} index={index} refForm={refForm}>
            <Button
              type="primary"
              htmlType="submit"
              size={item.size || "large"}
              loading={item.loading === undefined ? false : item.loading}
            >
              {item.label}
            </Button>
          </ColFormItemWrapper>
        )
      );
    case "reset":
      return (
        !disabled && (
          <ColFormItemWrapper item={item} index={index} refForm={refForm}>
            <Button
              style={{ marginTop: "40px" }}
              type="ghost"
              htmlType="reset"
              size={"large"}
              loading={item.loading === undefined ? false : item.loading}
            >
              Remettre à zéro
            </Button>
          </ColFormItemWrapper>
        )
      );
    case "addressForm":
      return (
        <AddressForm
          disabled={disabled}
          form={form}
          item={item}
          index={index}
          refForm={refForm}
          autoCompleteSearch={item.autoCompleteSearch}
          withLocator={item.withLocator}
        />
      );
    case "email":
      return (
        <ColFormItemWrapper item={item} index={index} refForm={refForm}>
          <Input
            addonBefore={<HiOutlineMail />}
            type={"email"}
            onChange={item.onChange !== undefined ? item.onChange : () => {}}
            defaultValue={""}
            disabled={item.disabled || disabled}
            placeholder={
              item.placeholder !== undefined ? item.placeholder : item.label
            }
          />
        </ColFormItemWrapper>
      );
    case "google-adress":
      return "Not installed";
    // <ColFormItemWrapper item={item} index={index} refForm={refForm}>
    //   <AutoCompleteCustom
    //     item={item}
    //     refForm={refForm}
    //     index={index}
    //     disabled={item.disabled || disabled}
    //     placeholder={
    //       item.placeholder !== undefined ? item.placeholder : item.label
    //     }
    //     onPlaceSelected={(place, address) => {
    //       // refForm;
    //     }}
    //   />
    // </ColFormItemWrapper>

    case "radio":
      return (
        <ColFormItemWrapper item={item} index={index} refForm={refForm}>
          <Radio.Group
            onChange={item.onChange}
            value={item.value}
            className={"customform-radio"}
          >
            <Space
              direction="vertical"
              style={{ marginBottom: 10 }}
              size={"large"}
            >
              {item.radios.map((rad) => {
                return (
                  <Radio value={rad.value}>
                    <Space direction="vertical">
                      <span style={{ fontWeight: 700 }}>{rad.label}</span>
                      <span style={{ fontWeight: 400 }}>{rad.description}</span>
                    </Space>
                  </Radio>
                );
              })}
            </Space>
          </Radio.Group>
        </ColFormItemWrapper>
      );
    case "checkbox":
      const { itemDisabled = [] } = item;
      return (
        <ColFormItemWrapper item={item} index={index} refForm={refForm}>
          <Checkbox.Group
            disabled={item.disabled || disabled}
            onChange={item.onChange}
            value={item.value}
            className={"customform-radio"}
          >
            <Space
              direction="vertical"
              style={{ marginBottom: 10 }}
              size={item?.size ? item.size : "large"}
            >
              {item.checkboxs.map((checkboxItem) => {
                return (
                  <Checkbox
                    value={checkboxItem.value}
                    disabled={
                      item.disabled ||
                      disabled ||
                      itemDisabled.includes(checkboxItem.value)
                    }
                  >
                    <Space direction="vertical">
                      <span style={{ fontWeight: 700 }}>
                        {checkboxItem.label}
                      </span>
                      {checkboxItem.description && (
                        <span style={{ fontWeight: 400 }}>
                          {checkboxItem.description}
                        </span>
                      )}
                    </Space>
                  </Checkbox>
                );
              })}
            </Space>
          </Checkbox.Group>
        </ColFormItemWrapper>
      );
    case "checkbox-bool":
      return (
        <ColFormItemWrapper item={item} index={index} refForm={refForm}>
          <label>
            <Checkbox
              disabled={item.disabled || disabled}
              onChange={(e) => {
                console.log(
                  e.target.checked,
                  item,
                  refForm,
                  form.getFieldsValue(true)
                );
                form.setFieldValue(item.name, "off");
              }}
              defaultChecked={item.value}
              className={"customform-check.bool"}
            ></Checkbox>
            <span style={{ marginLeft: 10 }}>{item.label}</span>
          </label>
        </ColFormItemWrapper>
      );
    case "radios":
      return (
        <ColFormItemWrapper item={item} index={index} refForm={refForm}>
          <Radio.Group
            disabled={item.disabled || disabled}
            onChange={item.onChange}
            value={item.value}
            className={"customform-radio"}
          >
            <Space
              direction="vertical"
              style={{ marginBottom: 10 }}
              size={item?.size ? item.size : "large"}
            >
              {item.radios.map((rad) => {
                return (
                  <Radio
                    value={rad.value}
                    disabled={item.disabled || disabled}
                    optionType={"default"}
                  >
                    <Space direction="vertical">
                      <span style={{ fontWeight: 700 }}>{rad.label}</span>
                      {rad.description && (
                        <span style={{ fontWeight: 400 }}>
                          {rad.description}
                        </span>
                      )}
                    </Space>
                  </Radio>
                );
              })}
            </Space>
          </Radio.Group>
        </ColFormItemWrapper>
      );
    case "upload":
      return (
        <ColFormItemWrapper item={item} index={index} refForm={refForm}>
          <Uploader item={item} index={index} refForm={refForm}></Uploader>
        </ColFormItemWrapper>
      );
  }
};
