// @ts-nocheck

import SvgFront from "../../svg/logo.svg";
import React, { useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../../engine/App.context";
import { ReactSVG } from "react-svg";
import InlineSVG from "../SvgReader";
import { SvgLoader, SvgProxy } from "react-svgmt";
import { colorByStatus, colors } from "../../utils/constants";
import "./style.css";
import Rose from "./Rose";
import { ReactComponent as SvgRotate } from "../../icons/rotate-icon.svg";
import svgjson from "svgson";
import { Configuration } from "../../engine/Configuration.interface";
export default function RootPlan(props) {
  const { plan } = props;
  const [selectors, setSelectors] = useState([]);
  const [ids, setIds] = useState([]);
  const [refSvgDom, setRefSvgDom] = useState(null);
  const [ready, setReady] = useState(false);


  // @ts-ignore
  const {
    layerHovered,
    layerClicked,
    previousLayerClicked,
    units,
    previousLayerHovered,
    layerOnClick,
    layersClicked,
    addLayerClicked,
    isMobile,
  } = useContext(AppContext);

  const { configuration } = useContext<AppContext>(AppContext) as {
    configuration: Configuration;
  };

  const svgRef = useRef();
  const refLayersClicked = useRef();
  const refProxy = useRef();
  const [key, setKey] = useState(Math.random);

  const layersRef = useRef([]);
  // you can access the elements with itemsRef.current[n]

  function areArraysEqual(arr1, arr2) {
    return arr1.length === arr2.length && arr1.every((el) => arr2.includes(el));
  }

  useEffect(() => {
    if (ready) {
      layersRef.current.map((ref) => {
        if (ref.elemRefs[0] !== undefined) {
          ref.elemRefs[0].addEventListener("click", (d) => {
            addLayerClicked(ref.unit.id, true);
          });
        }
      });
    }
  }, [ready]);

  useEffect(() => {
    if (ready === false && ids.length > 0 && selectors.length > 0) {
      let isEqual = areArraysEqual(ids, selectors);
      if (isEqual) {
        setReady(true);
      }
    }
  }, [ids, ready, selectors]);

  useEffect(() => {
    if (units.length > 0) {
      setSelectors(units.map((i) => i.id));
    }
  }, [units]);

  useEffect(() => {
    refLayersClicked.current = layersClicked;
  }, [layersClicked]);

  useEffect(() => {
    setKey(Math.random());
  }, [isMobile]);

  useEffect(() => {
    layersRef.current = layersRef.current.slice(0, selectors.length);
    // setReady(true);
  }, [selectors, plan]);


  const getRef = (id) => {
    return layersRef.current.find((layerRef) => layerRef.props.selector === id);
  };

  useEffect(() => {
    if (layersRef.current !== null) {
      if (!layersClicked.has(layerHovered)) {
        // Find the layer
        const selected = getRef(`#${layerHovered}`);
        if (selected !== undefined) {
          if (selected.elemRefs[0] !== undefined) {
            // selected.elemRefs[0].setAttribute("fill", "red");
            selected.elemRefs[0].setAttribute(
              "fill-opacity",
              String(configuration.front.opacity_hover)
            );
          } else {
            console.log(
              "This svg layer does not exist",
              selected.props.selector
            );
          }
        }
      }
    }
  }, [layerHovered, layersClicked]);

  useEffect(() => {
    if (layersRef.current !== null) {
      layersClicked.forEach((layer) => {
        // Find the layer
        const selected = getRef(`#${layer}`);
        if (selected !== undefined && selected.elemRefs[0] !== undefined) {
          selected.elemRefs[0].setAttribute(
            "fill-opacity",
            String(configuration.front.opacity_clicked)
          );
          //selected.elemRefs[0].setAttribute("stroke", "black");
        } else {
          console.log("This svg layer does not exist", selected.props.selector);
        }
      });
    }
  }, [layersClicked]);

  useEffect(() => {
    if (
      layersRef.current !== null &&
      previousLayerHovered !== null &&
      !layersClicked.has(previousLayerHovered)
    ) {
      // Find the layer
      const previous = getRef(`#${previousLayerHovered}`);

      if (previous !== undefined) {
        defaultStyle(
          previous.elemRefs[0],
          units.find((e) => e.id === previousLayerHovered)
        );
        //previous.elemRefs[0].removeAttribute("stroke");
      }
    }
  }, [previousLayerHovered, layersClicked]);

  useEffect(() => {
    if (layersRef.current !== null && previousLayerClicked !== null) {
      // Find the layer
      const previous = getRef(`#${previousLayerClicked}`);

      if (previous !== undefined) {
        defaultStyle(
          previous.elemRefs[0],
          units.find((e) => e.id === previousLayerClicked)
        );
        //previous.elemRefs[0].removeAttribute("stroke");
      }
    }
  }, [previousLayerClicked]);

  const defaultStyle = (polygonRef, unit) => {
    polygonRef?.setAttribute("fill", colorByStatus(unit.status));
    polygonRef?.setAttribute(
      "fill-opacity",
      String(configuration.front.opacity_default)
    );
    polygonRef?.setAttribute("stroke", "");

    polygonRef?.setAttribute("stroke-width", "0");
  };

  const parseSvgText = () => {};

  parseSvgText();

  const eventListeners = new Map();

  const addId = (newId) => {
    setIds((prevIds) => {
      if (prevIds.includes(newId)) {
        return prevIds; // return the current state if the ID is already present
      } else {
        return [...prevIds, newId]; // add the new ID to the array
      }
    });
  };

  //console.log(plan);

  // @ts-ignore
  return plan === null || selectors.length === 0 ? (
    <div
      width="100%"
      role="status"
      style={{ height: "100vh" }}
      className="w-full h-full  p-4 border border-gray-200 rounded shadow animate-pulse md:p-6 dark:border-sky-600"
    >
      <div
        style={{ height: "95vh" }}
        width="100%"
        className="flex items-center justify-center h-48 mb-4 bg-gray-300 rounded dark:bg-sky-100"
      >
        <svg
          width="100%"
          className="w-full h-12 text-gray-200 dark:text-sky-600"
          xmlns="http://www.w3.org/2000/svg"
          aria-hidden="true"
          fill="currentColor"
          viewBox="0 0 640 512"
        >
          <path d="M480 80C480 35.82 515.8 0 560 0C604.2 0 640 35.82 640 80C640 124.2 604.2 160 560 160C515.8 160 480 124.2 480 80zM0 456.1C0 445.6 2.964 435.3 8.551 426.4L225.3 81.01C231.9 70.42 243.5 64 256 64C268.5 64 280.1 70.42 286.8 81.01L412.7 281.7L460.9 202.7C464.1 196.1 472.2 192 480 192C487.8 192 495 196.1 499.1 202.7L631.1 419.1C636.9 428.6 640 439.7 640 450.9C640 484.6 612.6 512 578.9 512H55.91C25.03 512 .0006 486.1 .0006 456.1L0 456.1z" />
        </svg>
      </div>
    </div>
  ) : (
    <div className={"svg-parent"}>
      <div className={"svg-container"}>
        {/*<div className={"rotate-container"}>*/}
        {/*  <SvgRotate width={"100%"} />*/}
        {/*</div>*/}
        <Rose />
        <SvgLoader
          className={"svgFull"}
          width="100%"
          height={isMobile ? "40vh" : "100vh"}
          svgXML={plan}
          ref={(el) => {
            if (el !== null) {
              svgRef.current = el;
            }
          }}
        >
          {units.map((unit, ix) => {
            return (
              <SvgProxy
                key={ix}
                selector={`#${unit.id}`}
                //fill="blue"
                ref={(el) => {
                  if (el !== null && layersRef.current[ix] === undefined) {
                    addId(unit.id);
                    layersRef.current[ix] = { ...el, unit };
                  }
                }}
                onElementSelected={(r) => {
                  // Default style
                  defaultStyle(r, unit);

                  // r.addEventListener("click", (d) => {
                  //   addLayerClicked(unit.id, true);
                  // });

                  r.addEventListener("mouseover", (d) => {
                    // document.body.style.cursor = "pointer";
                    if (!refLayersClicked.current.has(unit.id)) {
                      r.setAttribute(
                        "fill-opacity",
                        String(configuration.front.opacity_hover)
                      );

                      r?.setAttribute(
                        "stroke",
                        configuration.front.stroke_hover_color
                      );

                      r?.setAttribute(
                        "stroke-width",
                        String(configuration.front.stroke_hover_width)
                      );

                      r.style.cursor = "pointer";
                    }

                    //console.log("mouseover ?", r, d);
                  });

                  r.addEventListener("mouseleave", (d) => {
                    if (!refLayersClicked.current.has(unit.id)) {
                      defaultStyle(r, unit);
                    }
                  });
                }}
              />
            );
          })}
        </SvgLoader>
      </div>
    </div>
  );
}
