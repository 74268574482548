import { labelPreneurDuBail, labelSecondPlaceHolder } from "./form.constants";
import dayjs from "../../utils/dayjs";

export const inputsForth = [
  {
    parentLabel: "Informations",
    children: [
      {
        label:
          "Avez-vous une assurance RC (obligatoire en cas d'attribution de l'appartement)?",
        placeholder: labelPreneurDuBail,
        name: "rc",
        value: "Oui",
        col: 24,
        required: true,

        type: "select",
        selectContent: [
          { value: "Oui", label: "Oui" },
          { value: "Non", label: "Non" },
        ],
      },
      {
        label: "Avez-vous des animaux ?",
        placeholder: labelPreneurDuBail,
        name: "animals",
        value: "Oui",
        required: true,
        col: 24,
        type: "select",
        selectContent: [
          { value: "Oui", label: "Oui" },
          { value: "Non", label: "Non" },
        ],
      },
      {
        label: "Si oui lesquels ?",
        placeholder: "Mentionner ici",
        name: "animals_type",
        value: "Oui",
        required: true,
        col: 24,
        type: "input",
      },
      {
        label: "Souhaiteriez-vous un garage?",
        placeholder: labelPreneurDuBail,
        name: "need_garage",
        value: "Oui",
        col: 24,
        required: true,
        type: "select",
        selectContent: [
          { value: "Oui", label: "Oui" },
          { value: "Non", label: "Non" },
        ],
      },
      {
        label: "Souhaiteriez-vous une place de parc ?",
        placeholder: labelPreneurDuBail,
        name: "need_parking",
        value: "Oui",
        col: 24,
        required: true,
        type: "select",
        selectContent: [
          { value: "Oui", label: "Oui" },
          { value: "Non", label: "Non" },
        ],
      },
      {
        label:
          "Par quel biais avez-vous eu connaissance de la mise en location de cet objet? ",
        placeholder: "Mentionner ici",
        name: "how_you_knowus",
        required: true,
        col: 24,
        type: "input",
      },
      {
        type: "children",
        render: () => {
          return (
            <>
              <span>
                Le/la/les soussigné/e/s certifie/nt n'avoir communiqué ci-dessus
                que des renseignements conformes à la réalité. Il(s)/elle(s)
                certifie/nt n'avoir omis aucune indication susceptible d'induire
                en erreur le bailleur. Le bailleur est autorisé à vérifier la
                véracité des données communiquées. Les locaux ont été visités
                par le/la/les candidat/e/s soussigné/e/s qui les accepte/nt dans
                leur état actuel, sans aucune réserve.
              </span>

              <br />
              <strong>
                <br />
                Les candidats sont rendus attentifs au fait que toutes les
                pièces et documents personnels transmis ne leur seront pas
                retournés.
              </strong>
              <br />
              <br />
              <br />
              <span>
                Si les locaux sont attribués au/x soussigné/e/s, il(s)/elle(s)
                s'engage/nt à verser une somme forfaitaire de CHF 200.00 + TVA,
                y compris en cas de renonciation, pour couvrir les frais
                administratifs du bailleur ou de son représentant.
              </span>
            </>
          );
        },
      },
    ],
  },
];
