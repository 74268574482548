import { labelPreneurDuBail, labelSecondPlaceHolder } from "./form.constants";
import dayjs from "../../utils/dayjs";

export const inputsFith = [
  {
    parentLabel: "Informations",
    children: [
      {
        type: "upload",
        label: "Un extrait de l'Office des poursuites officiel",
        required: true,
        name: "extrait_poursuite",
        col: 12,
      },
      {
        type: "upload",
        label:
          "Trois fiches de salaire ou contrat de travail ou copie taxation des impôts",
        required: true,
        name: "fiche_salaires",
        col: 12,
      },
      {
        type: "upload",
        label: "Copie carte d’identité ou permis de séjour\n" + "\n",
        name: "carte_identite",
        required: true,
        col: 12,
      },
      {
        type: "upload",
        label: "Copie police d’assurance RC\n" + "\n",
        required: true,
        name: "police_assurance",
        col: 12,
      },
    ],
  },
];
