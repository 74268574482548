import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import "dayjs/locale/fr";
import { daysJsFormat } from "./constants"; // Importer la locale française

export const tz = "Europe/Zurich";

const utcFix = (option, dayjsClass) => {
  dayjsClass.prototype.valueOf = function () {
    const addedOffset = !this.$utils().u(this.$offset)
      ? this.$offset + (this.$x.$localOffset || this.$d.getTimezoneOffset())
      : 0;
    return this.$d.valueOf() - addedOffset * 60 * 1000;
  };
};
// npm i -S dayjs/plugin/utc  --legacy-peer-deps
dayjs.locale("fr");

dayjs.extend(utcFix);
//
dayjs.extend(utc);
dayjs.extend(timezone);

dayjs.tz.setDefault(tz);

dayjs.prototype["customFormat"] = function (format = "") {
  return this.format(format);
  // return this.tz(tz).format(format);
};

// Here was the trick ! it look like there is a major bug on the format
// the trick is to get the direct date from the object
// https://github.com/wellcomecollection/wellcomecollection.org/blob/32af11a4169a0a91ff3e1efd2cf2bf919d61fb90/common/utils/dates.ts#L37-L45
// https://github.com/iamkun/dayjs/pull/1448
dayjs.prototype["custom$d"] = function (format = "") {
  return this.$d;
  // return this.tz(tz).format(format);
};

export const generalFormatAntd = (d) => {
  if (dayjs.isDayjs(d)) {
    return d?.customFormat(daysJsFormat.day);
  } else {
    return d;
  }
};

export default (d) => dayjs(d).tz(tz);
