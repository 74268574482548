// @ts-nocheck

/**
 *
 * CustomForm
 *
 */

import React, { memo, useEffect, useState } from "react";
// import PropTypes from 'prop-types';
// import styled from 'styled-components';
import {
  Card,
  Col,
  Collapse,
  DatePicker,
  Form,
  Input,
  notification,
  Row,
  Select,
  Skeleton,
} from "antd";
import "./style.css";
import "moment/locale/fr";
import { renderInputType } from "./renderInputtype";
import { flattenObject } from "../../utils/helpersFunction.js";

const { RangePicker } = DatePicker;

const { Panel } = Collapse;
const { TextArea } = Input;
const { Option } = Select;

function CustomForm({
  rule = null,
  dataSource,
  disabled = false,
  labelCol,
  wrapperCol,
  handleSubmit,
  onChangeData,
  resetOnFinish,
  refForm,
  form,
  onValuesChange,
  initialValues = null,
  style = {},
}) {
  // const [form] = Form.useForm();

  const [activekey, setActivekey] = useState([]);
  const [disabledState, setDisabledState] = useState(disabled);
  const [ready, setReady] = useState(false);

  const preventChangingRoute = () => {
    return true;
  };

  const initRuleForm = () => {
    if (rule !== null) {
      setReady(true);
    } else {
      setReady(true);
    }
  };

  useEffect(() => {
    initRuleForm();

    if (initialValues !== null) {
      const flatten = flattenObject(initialValues);

      dataSource.forEach((section) => {
        section.children
          .filter((o) => o !== null)
          .filter(
            (e) =>
              section !== null || e.visible === undefined || e.visible === true
          )
          .forEach((input) => {
            if (input.value !== undefined) {
              const key = input.name || input.label;
              delete flatten[key];
            }

            if (input.value === undefined) {
              const key = input.name || input.label;
              if (flatten[key] === "") {
                delete flatten[key];
              }
            }
          });
      });

      form.setFieldsValue(flatten);
    }
  }, []);

  useEffect(() => {
    const handleTabClose = (event) => {
      return true;

      // This dosent work
      event.preventDefault();

      return (event.returnValue = "");
    };

    window.addEventListener("beforeunload", handleTabClose);

    return () => {
      window.removeEventListener("beforeunload", handleTabClose);
    };
  }, []);

  const generateSubObject = (values) => {
    let copyValues = { ...values };

    // remove Empty value of select
    dataSource.map((section) => {
      section.children
        .filter((e) => e.visible === true || e.visible === undefined)
        .map((child) => {
          // Parse Date first
          if (child.hidden === undefined || child.hidden === false) {
            if (child.type === "addressForm") {
              // console.log(copyValues[child.name], child)
            }

            if (child.type === "date" || child.type === "date-accounting") {
              if (copyValues[child.name] !== null) {
                // dayjs handle badly the change between utc
                // console.log({
                //   date_getTimezoneOffset: new Date(
                //     copyValues[child.name].format(),
                //   ).getTimezoneOffset(),
                //   name: child.name,
                //   dayjs: copyValues[child.name],
                //   format: copyValues[child.name].format(),
                //   "utc().format()": copyValues[child.name].utc().format(),
                //   "utc(true).format()": copyValues[child.name]
                //     .utc(true)
                //     .format(),
                //   "tz(tz).format()": copyValues[child.name].tz(tz).format(),
                //   toString: copyValues[child.name].toString(),
                //   custom$d: copyValues[child.name].custom$d(),
                // });

                // let extractDate = copyValues[child.name].custom$d();
                // let date = new Date(extractDate);
                // console.log(date, extractDate);
                //
                // let values = form.getFieldValue(child.name);
                // console.log(values);

                if (copyValues[child.name] !== undefined) {
                  copyValues[child.name] = copyValues[child.name]
                    .custom$d()
                    .toString();
                }
              }
            }
          }

          if (child.type === "select" || child.type === "dynamic-select") {
            if (
              copyValues[child.name] !== undefined &&
              copyValues[child.name] !== null
            ) {
              if (
                copyValues[child.name] === "" ||
                copyValues[child.name].length === 0
              ) {
                delete copyValues[child.name];
              }
            }
          }
        });
    });

    Object.keys(copyValues).map((key) => {
      // Split by .
      const nameSplit = key.split(".");

      // create initial sub object
      if (copyValues[nameSplit[0]] === undefined) {
        copyValues[nameSplit[0]] = {};
      }

      // Sub object !
      // level 2
      if (nameSplit.length === 2) {
        // Mutate object
        copyValues[nameSplit[0]] = {
          ...copyValues[nameSplit[0]],
          [nameSplit[1]]: copyValues[key],
        };
        // Delete old ref
        delete copyValues[key];
      }
      // Level 3
      if (nameSplit.length === 3) {
        // Create initial sub sub object
        if (copyValues[nameSplit[0]][nameSplit[1]] === undefined) {
          copyValues[nameSplit[0]] = {
            ...copyValues[nameSplit[0]],
            [nameSplit[1]]: {},
          };
        }

        // Mutate object
        copyValues[nameSplit[0]] = {
          ...copyValues[nameSplit[0]],
          [nameSplit[1]]: {
            ...copyValues[nameSplit[0]][nameSplit[1]],
            [nameSplit[2]]: copyValues[key],
          },
        };

        // Delete old ref
        delete copyValues[key];
      }
    });

    Object.keys(copyValues).map((i) => {
      let source;
      // Get config source
      dataSource.forEach((e) => {
        let finder = e.children.find((p) => p.name === i);

        if (finder !== undefined) {
          source = finder;
        }
      });

      if (
        typeof copyValues[i] === "object" &&
        copyValues[i] !== null &&
        Object.keys(copyValues[i]).length === 0
      ) {
        // associate no value as default
        if (source !== undefined && source.defaultEmpty !== undefined) {
          copyValues[i] = source.defaultEmpty;
        } else {
          copyValues[i] = null;
        }
      }
    });

    // remove Empty
    return copyValues;
  };

  const onFinish = (values) => {
    if (!disabledState) {
      handleSubmit(generateSubObject(values), form);

      if (resetOnFinish) {
        form.resetFields();
      }
    }
  };

  const onFinishFailed = (errorInfo) => {
    if (!disabledState) {
      notification.error({
        message: errorInfo.errorFields.length + " champs sont nécessaires",
      });

      let recreateit = [];

      dataSource.map((e) => {
        let child = { ...e, children: [] };

        e.children
          .filter((e) => e.visible === true || e.visible === undefined)
          .map((o) => {
            if (
              o.componentLabel === undefined &&
              o.suffixIcon === undefined &&
              o.addonBefore === undefined
            ) {
              child.children.push(o);
            }
          });
        recreateit.push(child);
        return e;
      });

      // We dont take componentLabel because react element and stringify make it lose
      let copy = JSON.parse(JSON.stringify(recreateit));

      let keys = copy.filter((e) => e.collapse).map((e) => e.parentLabel);
      setActivekey(keys);

      console.log("Failed:", errorInfo);
    }
  };

  const onChangeActive = (a) => {
    setActivekey(a);
  };

  const onFieldsChange = (changedFields, allFields) => {
    onChangeData(form, changedFields, allFields);
  };

  const renderChild = (item) => {
    return item.children
      .filter((o) => o !== null)
      .filter((e) => e.visible === true || e.visible === undefined)
      .map((c, i) => {
        if (c?.hidden) {
          return null;
        } else {
          return renderInputType(
            c,
            i,
            form,
            refForm,
            disabledState,
            onValuesChange
          );
        }
      });
  };

  if (!ready) {
    return <Skeleton active />;
  }

  return (
    <Form

      style={style}
      initialValues
      ref={refForm}
      onFieldsChange={onFieldsChange}
      onValuesChange={onValuesChange}
      form={form}
      name="basic"
      labelCol={{ span: labelCol || 8 }}
      wrapperCol={{ span: wrapperCol || 16 }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      onReset={onFieldsChange}
      autoComplete="off"
      className={"custom-form"}
    >
      {dataSource.map((item, i) => {
        let Wrapper = null;

        if (item?.type === "card") {
          Wrapper = Card;
          Wrapper.style = {};
        } else {
          Wrapper = "div";
        }

        return (
          <Wrapper
            style={{
              width: "100%",
              marginBottom: 20,
            }}
          >
            <Row key={item.key} gutter={[5, 0]}>
              {item?.parentLabel !== null && (
                <Col span={24}>
                  <h3
                    style={{ marginTop: i > 0 ? 40 : 0 }}
                    className="ant-form-item-title"
                    // style={{ color: colors.blue }}
                  >
                    {item.parentLabel}
                  </h3>
                </Col>
              )}

              {item.collapse ? (
                <Collapse
                  defaultActiveKey={[item.defaultActiveKey]}
                  style={{ width: "100%" }}
                  ghost
                  onChange={onChangeActive}
                >
                  <Panel
                    className={"collaps-header-form"}
                    header={"Modifier les données par défaut"}
                    key={item.parentLabel}
                    style={{ width: "100%" }}
                    forceRender={true}
                  >
                    <Row key={item.key + "collapse"}>{renderChild(item)}</Row>
                  </Panel>
                </Collapse>
              ) : (
                renderChild(item)
              )}
            </Row>
          </Wrapper>
        );
      })}
    </Form>
  );
}

CustomForm.propTypes = {};

CustomForm.defaultProps = {
  onChangeData: () => {},
};

export default memo(CustomForm);
