// Set config defaults when creating the instance

import axios from "axios";
import { apiUrl } from "./constants";

let baseURL = apiUrl();

export const http = axios.create({
  baseURL: baseURL,
  timeout: 60000,
});

// Add a response interceptor
http.interceptors.response.use(
  function (response) {
    // Do something with response data
    return response;
  },
  function (error) {
    if (error.response.status === 403) {
    }

    // Do something with response error
    return Promise.reject(error);
  }
);

export const setAuthorizationHeader = (token = null) => {
  if (token) {
    http.defaults.headers.common.Authorization = `Token ${token}`;
  } else {
    delete http.defaults.headers.common.Authorization;
  }
};
