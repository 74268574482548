// @ts-nocheck

import { FormattedMessage, FormattedPlural, useIntl } from "react-intl";
import React from "react";

export const TranslatePlurial = ({ value, id, valueKey }) => {
  const intl = useIntl();

  return (
    <>
      {value <= 0 ? (
        <FormattedMessage
          id={`${id}.one`}
          values={{ count: value, linebreak: <br /> }}
        />
      ) : (
        <FormattedMessage
          id={`${id}.other`}
          values={{ count: value, linebreak: <br /> }}
        />
      )}
    </>
  );
};
