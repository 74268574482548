import SectionHeader from "./SectionHeader";
import Filters from "./Filters";
import Units from "./Units";
import { useContext } from "react";
import { AppContext } from "../engine/App.context";

export default function LeftSection() {
  // @ts-ignore
  const { isMobile } = useContext(AppContext);

  return (
    <div
      className={` ${
        isMobile ? "p-1   " : "p-5"
      } h-calc(100%)   flex flex-col overflow-y-scroll  `}
    >
      {/*<SectionHeader />*/}
      <Filters />
      <div
        className={` ${isMobile ? "h-[800px]" : "h-[100vh] mb-[-300px]"} flex flex-col `}
      >
        <div className="flex-grow">
          <Units />
        </div>
      </div>
    </div>
  );
}
