export const PORT_BACKEND = ":8080";
export const googleApiKey = "";

export const rootUrlDev = `http://localhost${PORT_BACKEND}`;
export const rootUrlProd = "https://nisekhome-demo.herokuapp.com";

const apisUrl = [
  { url: "https://nisekhome-demo.herokuapp.com", match: ["niseko"] },
  { url: "https://valoribox.herokuapp.com", match: ["valoribox"] },
  { url: "https://staging.herokuapp.com", match: ["valoribox"] },
];

const matchUrl = (url, match) => {
  return match.some((str) => url.includes(str));
};

export function getOrientationString(orientations) {
  const translationMap = {
    east: "E",
    south: "S",
    north: "N",
    west: "O",
  };

  return orientations.map((direction) => translationMap[direction]).join("-");
}

export const apiUrl = () => {
  if (process.env.NODE_ENV !== "production") {
    return `${rootUrlDev}/api`;
  } else {
    // Window url
    const currentUrl = window.location.href;

    // parse it, where i am ??

    const matchedUrl = apisUrl.find((api) => matchUrl(currentUrl, api.match));
    if (matchedUrl) {
      return matchedUrl.url + "/api";
    } else {
      alert("No url found, setup the backend");
      return "";
    }
  }
};

export const statusUnit = {
  available: "available",
  rented: "rented",
  reserved: "reserved",
  sold: "sold",
};

export const colors = {
  primary: "#113f5f",
  secondary: "#E59246",
  gray: "#A5A5A5",
  lightgray: "#e0e0e0",
};

export const colorByStatus = (status) => {
  if (status === statusUnit.available) {
    return colors.primary;
  }

  if (status === statusUnit.rented || status === statusUnit.sold) {
    return colors.gray;
  }

  if (status === statusUnit.reserved) {
    return colors.secondary;
  }
};

export const formatCurrency = (value, withDecorator = false) => {
  if (value === null || value === undefined) {
    return 0;
  }
  const number = value.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, "'");

  return `${withDecorator ? "" : ""}${number}${withDecorator ? ".-" : ""}`;
};

export const daysJsFormat = {
  day: "DD.MM.YYYY",
  hour: "HH:mm",
  full: "DD.MM.YYYY, HH:mm",
  fullTZ: "DD.MM.YYYY, HH:mm tz",
};
