/**
 *
 * ColFormItemWrapper
 *
 */

import React, { useEffect } from "react";
import { Col, Form, Tooltip } from "antd";
import PropTypes from "prop-types";
import { FiHelpCircle } from "react-icons/fi";
import { AiFillEye, AiOutlineEye } from "react-icons/ai";

// import PropTypes from 'prop-types';
// import styled from 'styled-components';

function uppercase(s) {
  return s.toUpperCase();
}

function capitalize(s) {
  return s && s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();
}

function removeDecimal(s) {
  if (s === null) {
    return 0;
  }

  return parseFloat(s).toFixed(3).slice(0, -1);
}

function ColFormItemWrapper({ item, index, children, refForm }) {
  // const [canRenderWatch, setCanRenderWatch] = React.useState(
  //   item.watch === undefined
  // );
  // const [canRenderWatchDynamic, setCanRenderWatchDynamic] = React.useState(
  //   item.watchDynamicObject === undefined
  // );

  // const [itemWatched, setItemWatched] = React.useState(null);

  // const watchedValue = item.watch ? Form.useWatch(item.watch) : null;
  // const watchedDynamicValue = item.watchDynamicObject
  //   ? Form.useWatch(item.watchDynamicObject)
  //   : null;

  // const filterChildrenList = (child) => {
  //   if (item.filterContent === undefined) {
  //     return true;
  //   } else {
  //     return item.filterContent(child, itemWatched);
  //   }
  // };
  // const previousItemWatched = React.useRef();

  // useEffect(() => {
  //   if (item.watchDynamicObject) {
  //     const dataDynamic = refForm.current[`_${item.watchDynamicObject}`];
  //
  //     // first one is dataDynamic
  //     // i want the previous one
  //     if (dataDynamic !== undefined) {
  //       let itemWatchedRef = dataDynamic.find(
  //         (e) => e._id === watchedDynamicValue
  //       );
  //
  //       // if (item.name === "applicant") {
  //       //   console.log(previousItemWatched, itemWatched);
  //       //   console.log(itemWatchedRef, itemWatched);
  //       // }
  //
  //       if (itemWatchedRef !== undefined) {
  //         setItemWatched(itemWatchedRef);
  //         setCanRenderWatchDynamic(true);
  //
  //         // If the item should be dynamically set to empty
  //         if (item.watchDynamicSetEmpty) {
  //           if (canRenderWatch && canRenderWatchDynamic) {
  //             const initValue = refForm.current.getFieldValue(item.name);
  //
  //             // if (item.name === "applicant") {
  //             //   console.log(initValue, item);
  //             // }
  //
  //             // init case
  //             if (initValue === undefined || initValue === "") {
  //               if (item.watchDynamicSetter) {
  //                 refForm.current.setFieldsValue({
  //                   [item.name]: item.watchDynamicSetter(itemWatched),
  //                 });
  //               }
  //             }
  //
  //             const hasPreviousId =
  //               previousItemWatched.current?._id !== undefined;
  //
  //             const hasCurrentId = itemWatchedRef._id !== undefined;
  //             // if (item.name === "applicant") {
  //             //   console.log({
  //             //     itemWatched,
  //             //     itemWatchedRef,
  //             //   });
  //             // }
  //
  //             // Update one !
  //             if (
  //               itemWatched._id !== itemWatchedRef._id &&
  //               item.watchDynamicSetter
  //             ) {
  //               // IDs are different, which means the watcher value has changed. Update the field value.
  //               refForm.current.setFieldsValue({
  //                 [item.name]: item.watchDynamicSetter(itemWatchedRef),
  //               });
  //             } else if (itemWatched._id !== itemWatchedRef._id) {
  //               // reset if no dynamic settter
  //               refForm.current.setFieldsValue({
  //                 [item.name]: null,
  //               });
  //             }
  //           }
  //         } else {
  //           const initValue = refForm.current.getFieldValue(item.name);
  //
  //           // If not set to watch dynamic changes, simply reset the field value to undefined.
  //           if (item.name) {
  //             refForm.current.setFieldsValue({
  //               [item.name]: undefined,
  //             });
  //           }
  //         }
  //
  //         if (itemWatched !== null && itemWatchedRef._id !== itemWatched?._id) {
  //           previousItemWatched.current = itemWatchedRef;
  //         }
  //       } else {
  //         console.log(
  //           "How can you watch a value does not exist ? Deleted item in the DB",
  //           item.name
  //         );
  //       }
  //     } else {
  //       setCanRenderWatchDynamic(false);
  //     }
  //   }
  //   // Watch the name
  //   if (item.watch) {
  //     // Watch dynamic values
  //     // watch static values
  //     if (item.watchValues) {
  //       if (item.watchValues.includes(watchedValue)) {
  //         setCanRenderWatch(true);
  //       } else {
  //         setCanRenderWatch(false);
  //       }
  //     }
  //
  //     if (item.watchValuesCallback) {
  //       const itemValue = refForm.current.getFieldValue(item.name);
  //       item.watchValuesCallback(itemValue, watchedValue, refForm);
  //     }
  //   }
  // }, [
  //   watchedValue,
  //   watchedDynamicValue,
  //   canRenderWatch,
  //   canRenderWatchDynamic,
  // ]);

  // TODO deal with initialValue and Value and rehyrate

  const { name, offset, displayLabel, required, rules = [], value, col } = item;

  const defaultRules = [
    {
      required: required || false,
      message: "Champs obligatoire",
    },
  ];

  const {
    label,
    quote,
    quoteIcon = "info",
    componentLabel,
    addonAfter,
    addonBefore,
    suffixIcon,
    ...rest
  } = item;

  const extraProps = JSON.parse(JSON.stringify(rest));

  if (item.type === "submit" || item.type === "checkbox-bool") {
    extraProps.label = null;
  }

  if (
    item.type === "input" &&
    item.capitalize !== undefined &&
    item.capitalize !== false
  ) {
    extraProps.normalize = (value) => capitalize(value);
  }
  if (item.type === "input" && item.uppercase === true) {
    extraProps.normalize = (value) => uppercase(value);
  }

  if (item.normalize !== undefined) {
    extraProps.normalize = (value) => item.normalize(value);
  }

  if (item.type !== "dynamic-select") {
    extraProps.initialValue = value;
  } else {
    if (value !== undefined && value !== null) {
      extraProps.initialValue = value;
    }
  }

  if (item.type === "select") {
    if (value === undefined || value?.length === 0) {
      // extraProps.initialValue = undefined;
    } else {
      extraProps.initialValue = value;
    }
  }

  if (item.type === "input-currency") {
    extraProps.validateTrigger = "onBlur";
    extraProps.trigger = "onBlur";
  }
  if (item.type === "checkbox-bool") {
    extraProps.valuePropName = "checked";
  }

  // if (item.type === 'input-currency') {
  //   extraProps.trigger = 'onValueChange';
  // }

  const renderQuote = () => {
    if (quote === undefined) {
      return null;
    } else {
      return (
        <Tooltip title={quote} color={"#343434"} showArrow={true} style={{}}>
          {quoteIcon === "info" && (
            <FiHelpCircle
              size={20}
              style={{ paddingLeft: 1, paddingRight: 1, marginLeft: 5 }}
            />
          )}
          {quoteIcon === "eye" && (
            <AiFillEye
              size={20}
              style={{ paddingLeft: 1, paddingRight: 1, marginLeft: 5 }}
            />
          )}
        </Tooltip>
      );
    }
  };

  const renderLabel = () => {
    if (label === undefined && componentLabel === undefined) {
      return null;
    }

    if (componentLabel === undefined) {
      return (
        <>
          {label} {renderQuote()}
        </>
      );
    }

    if (React.isValidElement(componentLabel)) {
      return (
        <>
          {componentLabel} {renderQuote()}
        </>
      );
    }
  };

  let extraClassName = "";

  if (item.variant === "important") {
    extraClassName = " important-form";
  }

  const renderChildren = () => {
    if (typeof children === "function") {
      return children();
      // return children({ filterChildrenList });
    }
    if (React.isValidElement(children)) {
      return children;
    }
    console.error("Invalid children passed to ColFormItemWrapper");
    return null;
  };

  // const canRenderWatchDynamic = () => {
  //   if (item.watchDynamicObject) {
  //     return itemWatched !== null;
  //   } else {
  //     return true;
  //   }
  // };

  const controlledRender = () => {
    return true;

    // if (item.watchRenderCondition) {
    //   return canRenderWatch && canRenderWatchDynamic;
    // } else {
    //   return true;
    // }
  };

  return controlledRender() ? (
    <Col
      style={{
        opacity: item.ready === false || item.disabled === true ? 1 : 1,
        textAlign: item.align ? item.align : "left",
      }}
      key={name + index + "-col"}
      span={col}
      xs={item.xs}
      sm={item.sm}
      md={item.md}
    >
      <Form.Item
        required={required}
        key={name + index + "-item"}
        name={name}
        label={renderLabel()}
        className={name + "-wrapper " + extraClassName}
        {...extraProps}
        rules={rules.length === 0 ? defaultRules : rules}
      >
        {renderChildren()}
      </Form.Item>
    </Col>
  ) : null;
}

ColFormItemWrapper.propTypes = {
  item: PropTypes.shape({
    col: PropTypes.number,
    name: PropTypes.string,
    label: PropTypes.string,
    offset: PropTypes.number,
    displayLabel: PropTypes.bool,
    required: PropTypes.bool,
    value: PropTypes.any,
  }),
  rules: PropTypes.array,
  index: PropTypes.number,
  children: PropTypes.node,
};

ColFormItemWrapper.defaultProps = {
  item: {
    rules: [],
  },
};

export default ColFormItemWrapper;
