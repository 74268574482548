// @ts-nocheck

import React, { useContext, useEffect, useState } from "react";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import { colors } from "../utils/constants";
import { AppContext } from "../engine/App.context";
import { FormattedMessage, FormattedPlural, useIntl } from "react-intl";
import { TranslatePlurial } from "./Translate";
import { Configuration } from "../engine/Configuration.interface";

export default function Filters() {
  // @ts-ignore
  const {
    addRoomSearch,
    roomsSearch,
    filterStatus,
    setFilter,
    units,
    isMobile,
    addFloorSearch,
    floorsSearch,
    updateNumberSearch,
    surfaceSearch,
    filterUniqueUnit,
    amountSearch,
    amountSellingSearch,
    setCheckedUniqueUnit,
    resetFilters,
    minMaxPriceSelling,
    minMaxSurface,
    minMaxPrice,
    scrollTo,
  } = useContext(AppContext);

  const { configuration } = useContext<AppContext>(AppContext) as {
    configuration: Configuration;
  };

  const { front } = configuration;
  const { filter_status } = front;

  const intl = useIntl();

  const [room, setRoom] = useState(2.5);
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (scrollTo !== null) {
      setShow(false);
    }
  }, [scrollTo]);

  const changeRoom = (v) => {
    setRoom(v.target.value);
  };

  const onPressRoom = (n) => {
    addRoomSearch(n);
  };

  // available, rented,  reserved, sold
  const roomFilters = [2.5, 3.5, 4.5, 5.5];
  const statusFilters = [
    { label: <FormattedMessage id={`disponible`} />, key: "available" },
    { label: <FormattedMessage id={`reserve`} />, key: "reserved" },
    { label: <FormattedMessage id={`loue`} />, key: "rented" },
    { label: <FormattedMessage id={`vendu`} />, key: "sold" },
  ];

  //  available, rented,  reserved, sold


  return (
    <div>
      <div
        className={`w-full flex flex-row ${
          isMobile ? "mb-1" : "mb-2"
        } md:flex-wrap lg:flex-nowrap`}
      >
        {filter_status.includes("available") && (
          <div className={"  w-1/3   flex-col flex "}>
            <span
              className={`text-center uppercase text-light ${
                isMobile ? "text-xs" : ""
              }`}
              style={{ color: colors.primary }}
            >
              <TranslatePlurial
                id={"filtersavailables"}
                value={units.filter((o) => o.status === "available").length}
              />
            </span>
          </div>
        )}

        {filter_status.includes("reserved") && (
          <div className={" sm:w-1/3   w-1/3 flex-col flex "}>
            <span
              className={`text-center uppercase text-light ${
                isMobile ? "text-xs" : ""
              }`}
              style={{ color: colors.secondary }}
            >
              <TranslatePlurial
                id={"filtersreserved"}
                value={units.filter((o) => o.status === "reserved").length}
              />
            </span>
          </div>
        )}

        {configuration.front.type_price === "rent"
          ? filter_status.includes("rented") && (
              <div className={"  sm:w-1/3  w-1/3  flex-col flex "}>
                <span
                  className={`text-center uppercase text-light ${
                    isMobile ? "text-xs" : ""
                  }`}
                  style={{ color: colors.gray }}
                >
                  <TranslatePlurial
                    id={"filtersrented"}
                    value={units.filter((o) => o.status === "rented").length}
                  />
                </span>
              </div>
            )
          : null}

        {configuration.front.type_price === "sell"
          ? filter_status.includes("sold") && (
              <div className={" sm:w-1/3  w-1/3  flex-col flex "}>
                <span
                  className={`text-center uppercase text-light ${
                    isMobile ? "text-xs" : ""
                  }`}
                  style={{ color: colors.gray }}
                >
                  <TranslatePlurial
                    id={"filterssold"}
                    value={units.filter((o) => o.status === "sold").length}
                  />
                </span>
              </div>
            )
          : null}
      </div>

      <p className={"text-center mb-3"}>
        <button
          style={{ backgroundColor: colors.primary }}
          className={`btn text-center btn-sm font-thin w-full ${
            isMobile ? "p-3 " : "p-8"
          }  align-baseline flex-nowrap  normal-case text-lg border-0 white\t`}
          onClick={() => setShow(!show)}
        >
          {show ? (
            <>
              <svg
                style={{ marginRight: 10 }}
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.5 0C2.43571 0 0 2.43571 0 5.5C0 8.56429 2.43571 11 5.5 11C8.56429 11 11 8.56429 11 5.5C11 2.43571 8.56429 0 5.5 0ZM7.62143 8.25L5.5 6.12857L3.37857 8.25L2.75 7.62143L4.87143 5.5L2.75 3.37857L3.37857 2.75L5.5 4.87143L7.62143 2.75L8.25 3.37857L6.12857 5.5L8.25 7.62143L7.62143 8.25Z"
                  fill="white"
                />
              </svg>
              <FormattedMessage id={"filters"} />
            </>
          ) : (
            <>
              <svg
                style={{ marginRight: 10 }}
                width="12"
                height="15"
                viewBox="0 0 12 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_27_4651)">
                  <path
                    d="M10.95 5H1.05C0.904131 5 0.764236 4.94732 0.661091 4.85355C0.557946 4.75979 0.5 4.63261 0.5 4.5C0.5 4.36739 0.557946 4.24021 0.661091 4.14645C0.764236 4.05268 0.904131 4 1.05 4H10.95C11.0959 4 11.2358 4.05268 11.3389 4.14645C11.4421 4.24021 11.5 4.36739 11.5 4.5C11.5 4.63261 11.4421 4.75979 11.3389 4.85355C11.2358 4.94732 11.0959 5 10.95 5Z"
                    fill="white"
                  />
                  <path
                    d="M8.80495 8H3.19495C3.06367 8 2.93776 7.94732 2.84493 7.85355C2.7521 7.75978 2.69995 7.63261 2.69995 7.5C2.69995 7.36739 2.7521 7.24021 2.84493 7.14645C2.93776 7.05268 3.06367 7 3.19495 7H8.80495C8.93623 7 9.06214 7.05268 9.15497 7.14645C9.2478 7.24021 9.29995 7.36739 9.29995 7.5C9.29995 7.63261 9.2478 7.75978 9.15497 7.85355C9.06214 7.94732 8.93623 8 8.80495 8Z"
                    fill="white"
                  />
                  <path
                    d="M6.9375 11H5.0625C4.91332 11 4.77024 10.9473 4.66475 10.8536C4.55926 10.7598 4.5 10.6326 4.5 10.5C4.5 10.3674 4.55926 10.2402 4.66475 10.1464C4.77024 10.0527 4.91332 10 5.0625 10H6.9375C7.08668 10 7.22976 10.0527 7.33525 10.1464C7.44074 10.2402 7.5 10.3674 7.5 10.5C7.5 10.6326 7.44074 10.7598 7.33525 10.8536C7.22976 10.9473 7.08668 11 6.9375 11Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_27_4651">
                    <rect
                      width="11"
                      height="15"
                      fill="white"
                      transform="translate(0.5)"
                    />
                  </clipPath>
                </defs>
              </svg>
              <FormattedMessage id={"filters"} />
            </>
          )}
        </button>
      </p>
      <cite
        style={{
          color: "#888",
          fontSize: "13px",
          fontWeight: "200",
          marginBottom: "20px",
        }}
      >
        <FormattedMessage id={"extra"} />
      </cite>
      {show ? (
        <>
          {front.filter_room && (
            <div className={"flex items-center flex-col pt-4  "}>
              <span className={" w-full uppercase mb-2"}>
                <FormattedMessage id={"pieces"} />
              </span>
              <div className=" w-full  ">
                {roomFilters.map((num) => {
                  return (
                    <button
                      className={`custom-btn mb-2  ${
                        roomsSearch.has(num) ? "active-custom-btn" : ""
                      }
                `}
                      onClick={() => onPressRoom(num)}
                    >
                      {num}
                    </button>
                  );
                })}
              </div>
            </div>
          )}

          {front.show_filter_status && (
            <div className={"flex items-center flex-col pt-4  "}>
              <span className={" w-full uppercase mb-2"}>
                <FormattedMessage id={"status"} />
              </span>
              <div className=" w-full  ">
                {statusFilters.map((num) => {
                  if (filter_status.includes(num.key)) {
                    return (
                      <button
                        className={`custom-btn mb-2  ${
                          filterStatus === num.key ? "active-custom-btn" : ""
                        }
                `}
                        onClick={() => setFilter(num.key)}
                      >
                        {num.label}
                      </button>
                    );
                  }
                })}
              </div>
            </div>
          )}

          {front.filter_floor && (
            <div className={"flex items-center flex-col pt-4"}>
              <span className={" w-full uppercase mb-2"}>
                <FormattedMessage id={"etages"} />
              </span>
              <div className=" w-full  ">
                <button
                  className={`custom-btn uppercase mb-2 ${
                    floorsSearch.has(1) ? "active-custom-btn" : ""
                  }`}
                  onClick={() => addFloorSearch(1)}
                >
                  1er
                </button>
                <button
                  className={`custom-btn uppercase mb-2 ${
                    floorsSearch.has(2) ? "active-custom-btn" : ""
                  }`}
                  onClick={() => addFloorSearch(2)}
                >
                  2ème
                </button>
                <button
                  className={`custom-btn uppercase mb-2 ${
                    floorsSearch.has(3) ? "active-custom-btn" : ""
                  }`}
                  onClick={() => addFloorSearch(3)}
                >
                  3ème
                </button>
                <button
                  className={`custom-btn uppercase mb-2 ${
                    floorsSearch.has(4) ? "active-custom-btn" : ""
                  }`}
                  onClick={() => addFloorSearch(4)}
                >
                  4ème
                </button>
              </div>
            </div>
          )}

          {configuration.front.type_price === "rent" && (
            <div className={"flex items-center flex-col p-4 mb-5"}>
              <span className={" w-full uppercase mb-2"}>
                <FormattedMessage id={"loyer"} /> <sup>chf</sup>
              </span>
              <Slider
                range
                min={minMaxPrice.min}
                max={minMaxPrice.max}
                step={100}
                defaultValue={amountSearch}
                value={amountSearch}
                marks={{
                  [amountSearch[0]]: amountSearch[0],
                  [amountSearch[1]]: amountSearch[1],
                }}
                onChange={(values) => {
                  updateNumberSearch("amountSearch", values);
                }}
                railStyle={styles.railStyle}
                dotStyle={styles.dotStyle}
                handleStyle={styles.handleStyle}
                activeDotStyle={styles.activeDotStyle}
                trackStyle={styles.trackStyle}
              />
            </div>
          )}

          {configuration.front.type_price === "sell" && (
            <div className={"flex items-center flex-col p-4 mb-5"}>
              <span className={" w-full uppercase mb-2"}>
                <FormattedMessage id={"priceselling"} /> <sup>chf</sup>
              </span>
              <Slider
                range
                min={minMaxPriceSelling.min}
                max={minMaxPriceSelling.max}
                step={10000}
                defaultValue={amountSellingSearch}
                value={amountSellingSearch}
                marks={{
                  [amountSellingSearch[0]]: amountSellingSearch[0],
                  [amountSellingSearch[1]]: amountSellingSearch[1],
                }}
                onChange={(values) => {
                  updateNumberSearch("amountSellingSearch", values);
                }}
                railStyle={styles.railStyle}
                dotStyle={styles.dotStyle}
                handleStyle={styles.handleStyle}
                activeDotStyle={styles.activeDotStyle}
                trackStyle={styles.trackStyle}
              />
            </div>
          )}
          <div className={"flex items-center flex-col p-4 mb-5"}>
            <span className={" w-full uppercase mb-2"}>
              <FormattedMessage id={"surface"} /> <sup>m2</sup>
            </span>
            <Slider
              range
              step={5}
              min={minMaxSurface.min}
              max={minMaxSurface.max}
              defaultValue={surfaceSearch}
              value={surfaceSearch}
              marks={{
                [surfaceSearch[0]]: surfaceSearch[0],
                [surfaceSearch[1]]: surfaceSearch[1],
              }}
              onChange={(values) => {
                updateNumberSearch("surfaceSearch", values);
              }}
              railStyle={styles.railStyle}
              dotStyle={styles.dotStyle}
              handleStyle={styles.handleStyle}
              activeDotStyle={styles.activeDotStyle}
              trackStyle={styles.trackStyle}
            />
          </div>

          <div className={"flex items-center flex-row p-4 justify-center"}>
            <span>
              <FormattedMessage id={`showOnlyAvailable`} />
            </span>
            <input
              onClick={() => setCheckedUniqueUnit()}
              type="checkbox"
              className="toggle toggle-lg ml-4"
              checked={filterUniqueUnit}
            />
          </div>

          <div className={"flex items-center flex-col p-4"}>
            <button
              style={{ color: colors.primary, borderColor: colors.gray }}
              className="btn text-center btn-sm btn-ghost font-thin normal-case bg-white text-base"
              onClick={resetFilters}
            >
              <FormattedMessage id={`reset`} />
            </button>
          </div>
        </>
      ) : null}
    </div>
  );
}

const styles = {
  railStyle: { backgroundColor: "#D9D9D9", height: 6 },
  dotStyle: { backgroundColor: "red", borderColor: "black" },
  handleStyle: {
    backgroundColor: "white",
    borderColor: colors.primary,
    opacity: 1,
    width: 30,
    height: 30,
    marginTop: -13,
  },
  activeDotStyle: { backgroundColor: "red", borderColor: "red" },
  trackStyle: { backgroundColor: colors.primary, height: 6 },
};
