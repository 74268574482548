import { labelPreneurDuBail, labelSecondPlaceHolder } from "./form.constants";
import dayjs from "../../utils/dayjs";

export const inputsThird = [
  {
    parentLabel: "Informations",
    children: [
      {
        label:
          "Le/la/les soussigné/e/s autorise/nt le bailleur ou son représentant à prendre les renseignements utiles auprès de l'Office des poursuites, de l'administration fiscale ainsi que de votre ancienne régie ",
        placeholder: labelPreneurDuBail,
        name: "allowed_poursuite",
        value: "Oui",
        col: 24,
        type: "select",
        selectContent: [
          { value: "Oui", label: "Oui" },
          { value: "Non", label: "Non" },
        ],
      },
      {
        label: "Le logement sera-til ?",
        placeholder: labelPreneurDuBail,
        name: "logement_goal",
        col: 24,
        value: "Principal",
        type: "select",
        selectContent: [
          { value: "Principal", label: "Principal" },
          { value: "Secondaire", label: "Secondaire" },
        ],
      },
      {
        label:
          "Si logement principal, sera-t-il occupé par des personnes mariées?",
        placeholder: labelPreneurDuBail,
        name: "principal_weidding",
        value: "Non",
        col: 24,
        type: "select",
        selectContent: [
          { value: "Oui", label: "Oui" },
          { value: "Non", label: "Non" },
        ],
      },
      {
        label:
          "Noms des personnes qui occuperont l'appartement (si différent des preneurs du bail):",
        placeholder: "Mentionner ici",
        name: "other_names",
        col: 24,
        type: "textarea",
        row: 2,
      },
      {
        label: "Avez-vous des poursuites en cours ?",
        name: "has_poursuite",
        value: "Non",
        col: 24,
        type: "select",
        selectContent: [
          { value: "Oui", label: "Oui" },
          { value: "Non", label: "Non" },
        ],
      },
      {
        label: "Avez-vous un curateur ?",
        name: "has_curateur",
        value: "Non",
        col: 24,
        type: "select",
        selectContent: [
          { value: "Oui", label: "Oui" },
          { value: "Non", label: "Non" },
        ],
      },

      {
        label: "Si oui, Nom, adresse et tèl du curateur",
        name: "curateur_contact",
        col: 24,
        type: "input",
        required: true,
      },
      {
        label: "Si oui, pour quel type de curatelle ?",
        name: "type_curatel",
        value: "None",
        col: 24,
        type: "select",
        selectContent: [
          { value: "None", label: "Je n'ai pas" },
          { value: "D'accompagnement", label: "D'accompagnement" },
          { value: "De représentation", label: "De représentation" },
          { value: "De coopération", label: "De coopération" },
          { value: "De portée générale", label: "De portée générale" },
        ],
      },
    ],
  },
];
