/**
 *
 * SelectAddItem
 *
 */

// import PropTypes from 'prop-types';
// import styled from 'styled-components';
// import { Link, useHistory } from 'react-router-dom';
import React, { useState } from "react";
import { Divider, Input, Select, Space, Typography } from "antd";
import { PlusOutlined } from "@ant-design/icons";
// styles
// antd component
//import { Card } from 'antd';
//const { Meta } = Card;
// icons
//import CustomIcons from 'components/CustomIcons';
//import defaultUnitImage from 'images/default.jpg';
// components
import ColFormItemWrapper from "../ColWFormItemWrapper";

const { Option } = Select;

let index = 0;
// import useDeviceDetect from 'utils/useDeviceDetect';

// global user
// import { withUser } from 'engine/Contexts/User.context';

function SelectAddItem({
  // default props
  className,
  item,
  ...rest
}) {
  const [items, setItems] = useState(item.selectContent);
  const [name, setName] = useState("");

  const onNameChange = event => {
    setName(event.target.value);
  };

  const addItem = e => {
    e.preventDefault();
    setItems([...items, { label: name, value: name } || `New item ${index++}`]);
    setName("");
  };

  return (
    <ColFormItemWrapper item={item} index={index}>
      <Select
        disabled={item.disabled}
        mode={"multiple"}
        style={{ width: "100%" }}
        placeholder="Sélectionner"
        dropdownRender={menu => (
          <>
            {menu}
            <Divider style={{ margin: "8px 0" }} />
            <Space align="center" style={{ padding: "0 8px 4px" }}>
              <Input
                placeholder="Nouveau tag"
                value={name}
                onChange={onNameChange}
              />
              <Typography.Link
                onClick={addItem}
                style={{ whiteSpace: "nowrap" }}
              >
                <PlusOutlined /> Ajouter
              </Typography.Link>
            </Space>
          </>
        )}
      >
        {items.map(item => (
          <Option key={item.value} value={item.value}>
            {item.label}
          </Option>
        ))}
      </Select>
    </ColFormItemWrapper>
  );
}

SelectAddItem.defaultProps = {};
SelectAddItem.propTypes = {};

export default SelectAddItem;
