// @ts-nocheck

import LeftSection from "../components/LeftSection";
import RootPlan from "../components/PlanReader/RootPlan";
import { NavBar } from "../components/NavBar";
import { TopFilters } from "../components/TopFilters";
import React, { useContext } from "react";
import { AppContext } from "./App.context";
import { IntlProvider, useIntl } from "react-intl";
import frTranslations from "../utils/langs/fr.json";
import deTranslations from "../utils/langs/de.json";
import { SvgLoading } from "../components/SvgLoading";

function Layout() {
  // @ts-ignore
  const {
    isMobile,
    units,
    svgPlan,
    configuration,
    direction,
    svg_north,
    svg_south,
    svg_east,
    svg_west,
  } = useContext(AppContext);
  const urlParams = new URLSearchParams(window.location.search);

  const [locale, setLocale] = React.useState(urlParams.get("lang") || "fr");

  const getMessages = () => {
    if (locale === "fr") {
      return frTranslations;
    } else if (locale === "de") {
      return deTranslations;
    } else {
      // Default if no lang
      return frTranslations;
    }
  };

  //  console.log(configuration, direction, svg_north);

  let plan = null;

  switch (direction) {
    case "north":
      plan = svg_north;
      break;
    case "south":
      plan = svg_south;
      break;
    case "west":
      plan = svg_west;
      break;
    case "east":
      plan = svg_east;
      break;
  }

  // console.log({ direction, svg_north, svg_south, svg_east, svg_west });

  return (
    <IntlProvider locale={locale} defaultLocale="fr" messages={getMessages()}>
      <div className={` ${isMobile ? "" : "fixed"} w-full`}>
        {/*<NavBar />*/}
        {/*<div className={""} style={{ flex: 1 }}>*/}
        {/*  <TopFilters />*/}
        {/*</div>*/}
        <div className={"w-full flex "}>
          <div className={"w-full "} style={{}}>
            <RootPlan key={direction} plan={plan} />
            {isMobile && <LeftSection />}
          </div>
          {!isMobile && (
            <div className={" left-section "}>
              <LeftSection />
            </div>
          )}
        </div>
      </div>
    </IntlProvider>
  );
}

export default Layout;
