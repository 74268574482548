import { IntlProvider, useIntl } from "react-intl";
import { AppProvider } from "./engine/App.context";
import Layout from "./engine/Layout";

import "./index.css";
import frTranslations from "./utils/langs/fr.json";



function App() {
  return (
    <AppProvider>
      <Layout />
    </AppProvider>
  );
}

export default App;
