// @ts-nocheck

import React, { useContext, useState } from "react";
import { AppContext } from "../engine/App.context";
import Unit from "./Unit";
import { colors, statusUnit } from "../utils/constants";
import { Configuration } from "../engine/Configuration.interface";
import { FormattedMessage } from "react-intl";

function sortByName(a, b) {
  const aNameParts = a.info.name.match(/^(.*?)(\d+)?$/);
  const bNameParts = b.info.name.match(/^(.*?)(\d+)?$/);
  const aPrefix = aNameParts[1].toLowerCase();
  const bPrefix = bNameParts[1].toLowerCase();
  const aNumber = parseInt(aNameParts[2] || "0");
  const bNumber = parseInt(bNameParts[2] || "0");
  if (aPrefix < bPrefix) return -1;
  if (aPrefix > bPrefix) return 1;
  if (aNumber < bNumber) return -1;
  if (aNumber > bNumber) return 1;
  return 0;
}

const Units: React.FC = () => {
  // @ts-ignore
  const {
    units,
    svgPlan,
    roomsSearch,
    floorsSearch,
    surfaceSearch,
    filterStatus,
    amountSearch,
    amountSellingSearch,
    filterUniqueUnit,
    ready,
    direction,
  } = useContext(AppContext);

  const { configuration } = useContext<AppContext>(AppContext) as {
    configuration: Configuration;
  };

  let filteredUnits = units;

  if (roomsSearch.size > 0) {
    filteredUnits = filteredUnits.filter((u) => {
      return roomsSearch.has(u.info.numberRoom);
    });
  }

  if (floorsSearch.size > 0) {
    filteredUnits = filteredUnits.filter((u) => {
      return floorsSearch.has(u.info.floor);
    });
  }

  if (configuration.front.type_price === "rent") {
    filteredUnits = filteredUnits.filter((u) => {
      return u.info.price >= amountSearch[0] && u.info.price <= amountSearch[1];
    });
  }

  if (configuration.front.type_price === "sell") {
    filteredUnits = filteredUnits.filter((u) => {
      return (
        u.info.priceSelling >= amountSellingSearch[0] &&
        u.info.priceSelling <= amountSellingSearch[1]
      );
    });
  }

  if (filterStatus !== null) {
    filteredUnits = filteredUnits.filter((e) => e.status === filterStatus);
  }

  filteredUnits = filteredUnits.filter((u) => {
    return (
      u.info.areaTotal >= surfaceSearch[0] &&
      u.info.areaTotal <= surfaceSearch[1]
    );
  });

  if (filterUniqueUnit) {
    filteredUnits = filteredUnits.filter((u) => {
      return u.status === statusUnit.available;
    });
  }

  if (ready === false) {
    // loading
    return (
      <div
        className="flex mx-2 mb-2 unit-box flex-col "
        style={{ borderColor: colors.lightgray }}
      >
        <h3
          className={"text-lg  font-bold  -mt-1 text-center p-20 "}
          style={{ color: colors.primary }}
        >
          <FormattedMessage id={`loading`} />
        </h3>
      </div>
    );
  }

  if (filteredUnits.length === 0) {
    return (
      <div
        className="flex mx-2 mb-2 unit-box flex-col "
        style={{ borderColor: colors.lightgray }}
      >
        <h3
          className={"text-lg  font-bold  -mt-1 text-center p-20 "}
          style={{ color: colors.primary }}
        >
          <FormattedMessage id={`noUnit`} />
        </h3>
      </div>
    );
  }

  return filteredUnits
    .filter((e) => e.info.orientation.includes(direction))
    .sort(sortByName)
    .map((u) => <Unit unit={u} key={u.id} />);
};

export default Units;
