import { Button, Modal, Steps, message, Form, notification } from "antd";
import { colors } from "../../utils/constants";
import { FormattedMessage } from "react-intl";
import React, { useRef, useState } from "react";
import CustomForm from "../CustomForm";
import { inputsFirst } from "./InputsFirst";
import { inputsSecond } from "./InputsSecond";
import { inputsThird } from "./InputsThird";
import { inputsForth } from "./InputsForth";
import { inputsFith } from "./InputsFith";
import { http } from "../../utils/http";

export const ModalApplicationForm = ({ unit }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [current, setCurrent] = useState(0);
  const formRef = useRef(null);
  const [currentError, setCurrentError] = useState(null);
  const [loading, setLoading] = useState(false);

  const [form] = Form.useForm();

  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const onSubmitForm = async () => {
    try {
      setLoading(true);
      const models = [
        ...inputsFirst[0].children,
        ...inputsSecond[0].children,
        ...inputsThird[0].children,
        ...inputsForth[0].children.filter((r) => {
          if (r.type !== "children") {
            return true;
          }
          return false;
        }),
        ...inputsFith[0].children,
      ];

      const data = formRef.current.getFieldsValue(true);

      const formData = new FormData();

      const jsonData = {
        models,
        data,
        unit,
      };

      // Append the JSON data
      // Convert the JSON object to a string
      formData.append("jsonData", JSON.stringify(jsonData));

      let files = models.filter((e) => e.type === "upload");
      files.forEach((e) => {
        data[e.name].forEach((file, i) => {
          const originalFileName = file.name;
          // Define the string you want to add before the file name
          const stringToAdd = e.name + "_" + i + "_";
          // Create the new file name by concatenating the string and the original file name
          const newFileName = stringToAdd + originalFileName;
          formData.append("files", file.originFileObj, newFileName);
        });
      });

      try {
        const sender = await http.post("/units/demande", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        console.log(sender);
        if (sender.status === 200) {
          notification.success({
            message: "Votre demande a été envoyée avec succès",
            description: "Nous vous contacterons dans les plus brefs délais.",
          });
          setIsModalOpen(false);
          setLoading(false);
          setCurrent(0);
          formRef.current.resetFields();
        }
      } catch (e) {
        console.log(e);
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };

  const steps = [
    {
      title: "Informations",
      content: (
        <>
          <CustomForm
            form={form}
            dataSource={inputsFirst}
            handleSubmit={(data) => onSubmitForm(data)}
            labelCol={24}
            refForm={formRef}
            wrapperCol={24}
            onValuesChange={undefined}
          />
        </>
      ),
    },
    {
      title: "Coordonnées & Situation",
      content: (
        <>
          <CustomForm
            form={form}
            dataSource={inputsSecond}
            handleSubmit={(data) => onSubmitForm(data)}
            labelCol={24}
            refForm={formRef}
            wrapperCol={24}
            onValuesChange={undefined}
          />
        </>
      ),
    },
    {
      title: "Légales",
      content: (
        <>
          <CustomForm
            form={form}
            dataSource={inputsThird}
            handleSubmit={(data) => onSubmitForm(data)}
            labelCol={24}
            refForm={formRef}
            wrapperCol={24}
            onValuesChange={undefined}
          />
        </>
      ),
    },
    {
      title: "Complémentaire",
      content: (
        <>
          <CustomForm
            form={form}
            dataSource={inputsForth}
            handleSubmit={(data) => onSubmitForm(data)}
            labelCol={24}
            refForm={formRef}
            wrapperCol={24}
            onValuesChange={undefined}
          />
        </>
      ),
    },
    {
      title: "Documents",
      content: (
        <>
          <CustomForm
            form={form}
            dataSource={inputsFith}
            handleSubmit={(data) => onSubmitForm(data)}
            labelCol={24}
            refForm={formRef}
            wrapperCol={24}
            onValuesChange={undefined}
          />
        </>
      ),
    },
  ];

  const validateStep = async (step) => {
    let valide = true;
    let error = false;
    console.log(step);

    switch (step) {
      case 0:
        try {
          let keys = inputsFirst.map((e) => e.children.map((e) => e.name));

          let validator = await form.validateFields(...keys);
          console.log(validator);
          setCurrentError(null);
        } catch (e) {
          setCurrentError(step);
          error = true;
        }
        break;
      case 1:
        try {
          let validator = await form.validateFields(
            ...inputsSecond.map((e) => e.children.map((e) => e.name))
          );
          console.log(validator);
          setCurrentError(null);
        } catch (e) {
          setCurrentError(step);
          error = true;
        }
        break;
      case 2:
        try {
          let validator = await form.validateFields(
            ...inputsThird.map((e) => e.children.map((e) => e.name))
          );
          console.log(validator);
          setCurrentError(null);
        } catch (e) {
          setCurrentError(step);
          error = true;
        }
        break;
      case 3:
        try {
          let validator = await form.validateFields(
            ...inputsForth.map((e) => e.children.map((e) => e.name))
          );
          console.log(validator);
          setCurrentError(null);
        } catch (e) {
          setCurrentError(step);
          error = true;
        }
        break;
      case 4:
        try {
          let validator = await form.validateFields(
            ...inputsFith.map((e) => e.children.map((e) => e.name))
          );
          console.log(validator);
          setCurrentError(null);
        } catch (e) {
          setCurrentError(step);
          error = true;
        }
        break;
    }

    if (!error && step <= 3) {
      next();
    }

    if (!error && step === 4) {
      formRef.current.submit();
    }
  };

  const items = steps.map((item) => ({ key: item.title, title: item.title }));

  return (
    <>
      <a
        href={"#"}
        style={{
          backgroundColor: "white",
          color: colors.primary,
          borderWidth: 0,
        }}
        className="btn text-center btn-sm font-thin p-6 align-baseline flex-nowrap normal-case text-base buttonLocation	"
        onClick={showModal}
      >
        <FormattedMessage id={`ask`} />
        <svg
          style={{ marginLeft: 6 }}
          width="5"
          height="9"
          viewBox="0 0 5 9"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M0.398832 0.516654C0.504694 0.410982 0.676176 0.411135 0.781848 0.516996L4.56674 4.30866C4.67228 4.41439 4.67228 4.58561 4.56674 4.69134L0.781848 8.483C0.676176 8.58887 0.504694 8.58902 0.398832 8.48335C0.292971 8.37767 0.292818 8.20619 0.39849 8.10033L3.99239 4.5L0.39849 0.89967C0.292818 0.793809 0.292971 0.622327 0.398832 0.516654Z"
            fill="#4F46E5"
          />
        </svg>
      </a>
      <Modal
        width={"auto"}
        style={{ maxWidth: "1200px", minWidth: "600px", width: "auto" }}
        title={<h1>Postulation en ligne</h1>}
        open={isModalOpen}
        onOk={handleOk}
        okText={"Fermer la fenêtre"}
        cancelText={"Fermer"}
        cancelButtonProps={{
          style: { color: "white", display: "none" },
          type: "ghost",
        }}
        onCancel={handleCancel}
      >
        <Steps current={current} items={items} />
        <div style={{}}>{steps[current].content}</div>
        <div className="steps-action" style={{ textAlign: "right" }}>
          {current > 0 && (
            <Button
              size={"large"}
              type={"primary"}
              loading={loading}
              style={{ width: "calc(50% - 16px)", margin: "0 8px" }}
              onClick={() => prev(current)}
            >
              Précédent
              {/*<FormattedMessage {...messages.prev} />*/}
            </Button>
          )}
          {current < steps.length - 1 && (
            <Button
              size={"large"}
              type={"primary"}
              loading={loading}
              onClick={() => validateStep(current)}
              style={{ width: "50%" }}
            >
              Suivant
              {/*<FormattedMessage {...messages.next} />*/}
            </Button>
          )}
          {current === steps.length - 1 && (
            <Button
              size={"large"}
              type={"primary"}
              htmlType="submit"
              loading={loading}
              style={{ width: "50%" }}
              //disabled={loading}
              // loading={loading}
              onClick={() => validateStep(current)}
            >
              Terminer et envoyer
              {/*<FormattedMessage {...messages.create} />*/}
            </Button>
          )}
        </div>
      </Modal>
    </>
  );
};
